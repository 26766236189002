import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import colors from 'theme/colors'

const Accordion = withStyles({
    root: {
        //border: '1px solid rgba(0, 0, 0, .125)',
        border: 0,
        padding: 0,
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: colors.lightGray,
        border: 0,
        padding: 0,
        //marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles(() => ({
    root: {
        padding: 0,
        backgroundColor: colors.lightGray,
    },
}))(MuiAccordionDetails)

export interface CustomAccordionProps {
    header: React.ReactNode
    sub: React.ReactNode
    hideIcon?: boolean
}

const CustomizedAccordions = (props: CustomAccordionProps): JSX.Element => {
    const { header, sub, hideIcon } = props
    const [expanded, setExpanded] = React.useState('')

    const handleChange = (panel: any) => (_: any, newExpanded: any) => {
        setExpanded(newExpanded ? panel : false)
    }

    return (
        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
                disableRipple={hideIcon}
                expandIcon={
                    <ExpandMoreIcon fontSize="large" style={{ color: hideIcon === true ? '#F3F0F0' : 'block' }} />
                }
            >
                {header}
            </AccordionSummary>
            <AccordionDetails onClick={() => setExpanded('')}>{sub}</AccordionDetails>
        </Accordion>
    )
}
export default CustomizedAccordions
