import { Box, Divider, Grid, Typography, Link } from '@material-ui/core'
import ContactForm from 'components/ContactForm'
import DashboardSectionBox from 'components/DashboardSectionBox'
import FaqTipsList from 'components/FaqTipsList'
import ViewWrapper from 'components/ViewWrapper'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { getHeader, navigationLinks } from 'utils/utils'
import TestIDs from '../../utils/TestIDs'
import useHelpOverviewState from './useHelpOverviewState'

const HelpOverview = (): JSX.Element => {
    const { t } = useTranslation()
    const history = useHistory()
    const { areFaqsCollapsable, faqList, tipsList, searchTerms, customize } = useHelpOverviewState()

    return (
        <ViewWrapper
            header={getHeader(Routes.Dashboard, history, searchTerms, customize)}
            navigation={navigationLinks('logged-in', 'help')}
        >
            <Box display={'flex'} flexDirection={'column'} marginBottom={7.5}>
                <Box width={1} marginBottom={9}>
                    <Typography variant={'h1'} {...TestIDs.GET_HELP_OVERVIEW('HEADLINE')}>
                        {t('pages.helpOverview.title')}
                    </Typography>
                    <Box marginTop={1}>
                        <Typography>{t('pages.helpOverview.subtitle')}</Typography>
                    </Box>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                        <Box marginBottom={7.5} {...TestIDs.GET_HELP_OVERVIEW('CONTACT_BOX')}>
                            <DashboardSectionBox title={t('pages.helpOverview.contact.title')}>
                                <>
                                    <Box marginY={2}>
                                        <Typography style={{ fontWeight: 'bold' }}>
                                            {t('pages.helpOverview.contact.byPhone')}
                                        </Typography>
                                        <Typography>
                                            <Link
                                                color={'inherit'}
                                                href={'tel:' + customize?.general.helpContact.telephone ?? ''}
                                            >
                                                {customize?.general.helpContact.telephone ?? ''}
                                            </Link>
                                        </Typography>
                                        {t('pages.helpOverview.contact.numberInfo') !==
                                            'pages.helpOverview.contact.numberInfo' && (
                                            <Typography
                                                dangerouslySetInnerHTML={{
                                                    __html: t('pages.helpOverview.contact.numberInfo'),
                                                }}
                                            />
                                        )}
                                    </Box>
                                    {t('pages.helpOverview.contact.servicehotline') !==
                                        'pages.helpOverview.contact.servicehotline' && (
                                        <Box marginY={2}>
                                            <Typography style={{ fontWeight: 'bold' }}>
                                                {t('pages.helpOverview.contact.servicehotline')}
                                            </Typography>
                                            <Typography
                                                dangerouslySetInnerHTML={{
                                                    __html: t('pages.helpOverview.contact.servicehotline_info'),
                                                }}
                                            />
                                        </Box>
                                    )}
                                    <Box marginY={2}>
                                        <Typography style={{ fontWeight: 'bold' }}>
                                            {t('pages.helpOverview.contact.byMail')}
                                        </Typography>
                                        <Typography>
                                            <Link
                                                color={'inherit'}
                                                href={'mailto:' + customize?.general.helpContact.email ?? ''}
                                            >
                                                {customize?.general.helpContact.email ?? ''}
                                            </Link>
                                        </Typography>
                                    </Box>
                                    <Box marginTop={2}>
                                        <Typography style={{ fontWeight: 'bold' }}>
                                            {t('pages.helpOverview.contact.byLetter')}
                                        </Typography>
                                        <Typography
                                            dangerouslySetInnerHTML={{
                                                __html: customize?.general.helpContact.address ?? '',
                                            }}
                                        />
                                    </Box>
                                </>
                            </DashboardSectionBox>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <ContactForm />
                    </Grid>
                    {customize &&
                    !customize.general.renderExternalFAQ &&
                    (faqList.length > 0 || tipsList.length > 0) ? (
                        <>
                            <Grid item xs={12}>
                                <Box marginTop={{ xs: 5, sm: 10 }} marginBottom={{ xs: 5, sm: 8 }}>
                                    <Divider />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'h2'} {...TestIDs.GET_HELP_OVERVIEW('HEADLINE_FAQ')}>
                                    {t('pages.helpOverview.faq.title')}
                                </Typography>
                            </Grid>
                            <FaqTipsList
                                faqList={faqList}
                                tipsList={tipsList}
                                categoryToHide={''}
                                displayMethod={
                                    areFaqsCollapsable ? t('url.displayMethod.collapse') : t('url.displayMethod.text')
                                }
                            />
                        </>
                    ) : (
                        <DashboardSectionBox title={`Weitere Tipps und FAQ's`}>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: t('pages.helpOverview.externalFAQ'),
                                }}
                            />
                        </DashboardSectionBox>
                    )}
                </Grid>
            </Box>
        </ViewWrapper>
    )
}

export default HelpOverview
