import { Grid, Typography } from '@material-ui/core'
import LoginEdit from 'components/LoginEdit'
import ViewWrapper from 'components/ViewWrapper'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { getHeader, navigationLinks } from 'utils/utils'
import useLoginEditPageState from './useLoginEditPageState'

const LoginEditPage = (): JSX.Element => {
    const history = useHistory()
    const { changeEmail, changePassword, user, loggedUser, customize } = useLoginEditPageState()
    if (user === undefined) {
        history.replace('/')
        return <></>
    }
    return (
        <ViewWrapper
            header={getHeader(Routes.Dashboard, history, [], customize)}
            navigation={navigationLinks('logged-in', 'myData')}
        >
            <Grid container direction="row" justify={'center'} alignItems={'flex-start'}>
                <Grid item xs={12}>
                    <Typography variant="h2">Login</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LoginEdit
                        userID={user ? user.customerID : ''}
                        email={loggedUser.email}
                        handleEmail={changeEmail}
                        handlePasswordReset={changePassword}
                        cancel={() => history.goBack()}
                    />
                </Grid>
            </Grid>
        </ViewWrapper>
    )
}

export default LoginEditPage
