import { ErrorBoxProps } from 'components/ErrorBox/ErrorBox'
import { SearchOption } from 'components/SearchBar/SearchBar'
import { User } from 'graphql/types'
import { History } from 'history'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { AppState } from 'store/store'
import { Customize } from 'utils/customize/customize'
import { dataHasErrors, editField } from './EditStateFuncs'

interface EditState {
    user: User
    userCopy: User
    history: History<unknown>
    searchTerms: SearchOption[]
    loading: boolean
    saveUserData: (data: User) => void
    setUserCopy: Dispatch<SetStateAction<User>>
    validation: ErrorBoxProps
    setValidation: Dispatch<SetStateAction<ErrorBoxProps>>
    setUserCopyPartial: (u: Partial<User>) => void
    touched: boolean
    setTouched: Dispatch<SetStateAction<boolean>>
    setUser: (payload: User) => void
    savePressed: boolean
    setSavePressed: Dispatch<SetStateAction<boolean>>
    customize: Customize | undefined
}

const useEditState = (editComponent: editField): EditState => {
    const history = useHistory()
    const { user, searchTerms, customize } = useSelector((appState: AppState) => ({
        user: appState.generalState.user,
        customize: appState.generalState.customize,
        searchTerms: appState.generalState.searchTerms,
    }))
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const [savePressed, setSavePressed] = useState(false)
    const [userCopy, setUserCopy] = useState<User>(user ?? ({} as User))
    const [touched, setTouched] = useState(false)
    const [validation, setValidation] = useState<ErrorBoxProps>({ errors: [], errorsTitle: undefined })
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()
    const setUser = useCallback(
        (payload: User) => {
            dispatch({ type: GeneralStateAction.SET_USER, payload })
        },
        [dispatch],
    )

    const saveUserData = (data: User): void => {
        enqueueSnackbar(t('notification.saveSuccess'), {
            variant: 'success',
        })
        setUser(data)
        setTouched(false)
    }

    const setUserCopyPartial = (u: Partial<User>): void => {
        setUserCopy({ ...userCopy, ...u })
    }
    useEffect(() => {
        if (userCopy && user && savePressed) {
            const { status, errors } = dataHasErrors(userCopy, editComponent)
            if (!status || errors.length > 0) {
                setValidation({
                    errorsTitle: 'registrationPersonalData',
                    errors: status ? errors : [...errors, 'missing_data'],
                })
            } else {
                setValidation({
                    errorsTitle: undefined,
                    errors: [],
                })
            }
        }

        if (user === undefined || userCopy === undefined) {
            history.replace('/')
        }
    }, [userCopy, savePressed])

    useEffect(() => {
        if (touched === false) {
            if (!_.isEqual(user, userCopy)) {
                setTouched(true)
            }
        }
    }, [userCopy, user])

    return {
        user: user ?? ({} as User),
        userCopy,
        history,
        searchTerms,
        loading: false,
        saveUserData,
        setUserCopy,
        validation,
        setUserCopyPartial,
        setValidation,
        touched,
        setTouched,
        setUser,
        savePressed,
        setSavePressed,
        customize,
    }
}

export default useEditState
