import { Box, Button, CircularProgress, Divider, Grid, Typography } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import { UsePaginationItem } from '@material-ui/lab/Pagination/usePagination'
import CheckboxComponent from 'components/CheckboxComponent'
import CustomPagination from 'components/CustomPagination/CustomPagination'
import Dropdown from 'components/Dropdown'
import { format } from 'date-fns'
import { Doc, SumBill } from 'graphql/types'
import _ from 'lodash'
import { InvoiceOrderField } from 'pages/MyInvoices/useMyInvoicesState'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'utils/formatCurrency'
import TestIDs from 'utils/TestIDs'
import { SortOrder } from 'utils/utils'
import * as Locales from 'date-fns/locale'

export interface InvoiceListMobileProps {
    invoices: Doc[]
    invoicesPerPage: number
    selectedInvoices: number[]
    downloadItems: (items: number[]) => void
    items: UsePaginationItem[]
    page: number
    dateOrder: SortOrder
    docTypeOrder: SortOrder
    changeOrder: (field: InvoiceOrderField, order?: SortOrder) => void
    selectAll: (e: React.ChangeEvent<HTMLInputElement>) => void
    selectOne: (index: number) => void
    billAmount: SumBill[]
    billIsLoading: boolean
}

const InvoiceListMobile = (props: InvoiceListMobileProps): JSX.Element => {
    const {
        invoices,
        invoicesPerPage,
        selectedInvoices: selected,
        downloadItems,
        page,
        changeOrder,
        selectOne,
        items,
        billAmount,
        billIsLoading,
    } = props
    const [order, setOrder] = useState<SortOrder>('desc')
    const { t } = useTranslation()
    const [sortedBy, setSortedBy] = useState<InvoiceOrderField>('invoiceTarget')

    return (
        <>
            <Box marginBottom={2}>
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <Dropdown
                            handleChange={(e) => {
                                if (e.target.value === 'invoiceTarget' || e.target.value === 'docType') {
                                    const field = e.target.value as InvoiceOrderField
                                    changeOrder(field, order)
                                    setSortedBy(field)
                                }
                            }}
                            label={t('formLabels.sortBy')}
                            options={[
                                { label: t('formLabels.sortTypes.date'), value: 'invoiceTarget' },
                                { label: t('formLabels.sortTypes.name'), value: 'docType' },
                            ]}
                            selectedOption={sortedBy}
                            small
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Dropdown
                            handleChange={(e) => setOrder(e.target.value as SortOrder)}
                            label={t('formLabels.sortDirection')}
                            options={[
                                { label: t('formLabels.sortDirections.asc'), value: 'asc' },
                                { label: t('formLabels.sortDirections.desc'), value: 'desc' },
                            ]}
                            selectedOption={order}
                            small
                        />
                    </Grid>
                </Grid>
            </Box>
            {_.slice(invoices, (page - 1) * invoicesPerPage, page * invoicesPerPage).map((row, index) => {
                const isItemSelected = selected.includes(index)
                const sum = billAmount.find((v) => v.docID === row.docID)
                return (
                    <React.Fragment key={index}>
                        <Divider />
                        <Box
                            marginTop={2}
                            marginBottom={1.5}
                            width={1}
                            display={'flex'}
                            flexDirection={'column'}
                            onClick={() => selectOne(index)}
                            {...TestIDs.GET_INVOICE_LIST_TABLE('CHECKBOX_ROW', 'SELECT_' + index)}
                        >
                            <Box width={1} display={'flex'} justifyContent={'space-between'}>
                                <Box paddingRight={0.25} display={'flex'} alignItems={'center'}>
                                    <Typography variant={'subtitle1'}>
                                        {row.invoiceTarget !== ''
                                            ? format(new Date(row.invoiceTarget), 'dd.MM.yy')
                                            : ''}
                                    </Typography>
                                </Box>
                                <Box fontWeight={'bold'}>
                                    {billIsLoading && <CircularProgress size={20} />}
                                    {!billIsLoading && (
                                        <Typography variant={'subtitle1'} noWrap style={{ fontWeight: 'inherit' }}>
                                            {sum ? formatCurrency(sum.sumTotal.brutto) : ''}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Box marginTop={1} width={1} display={'flex'} justifyContent={'space-between'}>
                                <Box display={'flex'} alignItems={'center'} marginRight={2}>
                                    <CheckboxComponent checked={isItemSelected} label={''} />
                                    <Typography variant={'caption'} style={{ wordBreak: 'break-word' }}>
                                        {t('docType.' + row.docType) + ' '}
                                        {row.invoiceTarget !== ''
                                            ? format(new Date(row.invoiceTarget), 'MMMM yyyy', { locale: Locales.de })
                                            : ''}
                                    </Typography>
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Button
                                        variant={'outlined'}
                                        className={'Small'}
                                        color={'secondary'}
                                        startIcon={<GetApp style={{ width: 20, height: 20 }} />}
                                        style={{ borderWidth: '2px' }}
                                        onClick={() => downloadItems([index])}
                                        {...TestIDs.GET_INVOICE_LIST_TABLE('PDF_BUTTON', 'DOWNLOAD_' + index)}
                                    >
                                        PDF
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </React.Fragment>
                )
            })}
            <Box width={1} marginTop={2} marginBottom={2}>
                <Button
                    {...TestIDs.GET_INVOICE_LIST_TABLE('DOWNLOAD_ALL_BUTTON')}
                    disabled={selected.length === 0}
                    variant={'contained'}
                    color={'primary'}
                    fullWidth
                    onClick={() => downloadItems(selected)}
                >
                    {t('pages.myInvoices.invoiceList.download')}
                </Button>
            </Box>
            <CustomPagination items={items} isMobile={true} />
        </>
    )
}

export default InvoiceListMobile
