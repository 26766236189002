import { gql } from '@apollo/client'

export const SEARCH_DOCS = gql`
    query searchDocs($docType: [String!]!) {
        searchDocs(docType: $docType) {
            bucket
            contractID
            customerID
            docType
            path
            s3Host
            invoiceTarget
            targetPeriodId
            displayName
            downloadName
            docID
        }
    }
`
