import { Box, Button, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit'
import { User } from 'graphql/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import colors from 'theme/colors'
import { Routes } from 'routes/routes'
import TestIDs from 'utils/TestIDs'

export interface PersonalDataProps {
    user: User
}

const PersonalData = (props: PersonalDataProps): JSX.Element => {
    const { user } = props
    const history = useHistory()
    const { t } = useTranslation()
    const salutation =
        user.salutation && user.salutation.trim().length > 0 ? t(`formLabels.salutationType.${user.salutation}`) : ''
    return (
        <>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={8}>
                    <Typography variant={'h2'}>{t('pages.myData.mainData.title')}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Box display="flex" flex={1} justifyContent={'flex-end'}>
                        <Button
                            variant={'outlined'}
                            className={'EditButton'}
                            onClick={() => history.push(Routes.PersonalDataEdit)}
                            startIcon={<EditIcon style={{ width: 20, height: 20 }} />}
                            TouchRippleProps={{ style: { color: colors.main } }}
                            {...TestIDs.GET_PERSONAL_DATA('EDIT_BUTTON')}
                        >
                            {t('general.edit')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box padding={{ xs: 2, sm: 5 }} marginTop={'35px'} bgcolor={colors.lightGray}>
                <Grid container spacing={2} direction="column" alignItems="flex-end">
                    <Grid item style={{ height: 0 }}></Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={3}>
                            <Typography style={{ color: colors.midDarkerGray }}>
                                {t('pages.myData.mainData.salutation')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography>{salutation}</Typography>
                        </Grid>
                    </Grid>
                    {user.company && user.company.trim().length > 0 && (
                        <Grid item container xs={12}>
                            <Grid item xs={12} sm={3}>
                                <Typography style={{ color: colors.midDarkerGray }}>
                                    {t('pages.myData.mainData.company')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <Typography>{user.company + ' ' + user.companyLegalForm}</Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={3}>
                            <Typography style={{ color: colors.midDarkerGray }}>
                                {t('pages.myData.mainData.name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography>{user.firstName + ' ' + user.lastName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={3}>
                            <Typography style={{ color: colors.midDarkerGray }}>
                                {t('pages.myData.mainData.address')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography>
                                {user.connectionAddress.street +
                                    ' ' +
                                    user.connectionAddress.houseNumber +
                                    ' ' +
                                    user.connectionAddress.houseNumberExtension}
                            </Typography>
                            <Typography>
                                {user.connectionAddress.zipCode + ' ' + user.connectionAddress.city}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={3}>
                            <Typography style={{ color: colors.midDarkerGray }}>
                                {t('pages.myData.mainData.email')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography>{user.email}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={3}>
                            <Typography style={{ color: colors.midDarkerGray }}>
                                {t('pages.myData.mainData.telephone')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography>{user.telephone}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={3}>
                            <Typography style={{ color: colors.midDarkerGray }}>
                                {t('pages.myData.mainData.mobile')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography>{user.mobile}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={3}>
                            <Typography style={{ color: colors.midDarkerGray }}>
                                {t('pages.myData.mainData.birthdate')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography>{user.birthdate}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default PersonalData
