import { useLazyQuery, useMutation } from '@apollo/client'
import { Box, Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'
import CheckboxComponent from 'components/CheckboxComponent'
import Dropdown from 'components/Dropdown'
import FieldWrapper from 'components/FieldWrapper'
import { toDropDown } from 'components/NumberBlock/NumberBlock'
import ViewWrapper from 'components/ViewWrapper'
import { UPDATE_PHONE_BLOCKED_DATA } from 'graphql/mutations/UpdatePhoneBlockedData'
import { GET_PHONEBLOCKED_DATA } from 'graphql/queries/GetPhoneBlockedData'
import {
    Mutation,
    MutationUpdatePhoneBlockDataArgs,
    PhoneBlockedData,
    Query,
    QueryGetPhoneBlockedDataArgs,
} from 'graphql/types'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { AppState } from 'store/store'
import colors from 'theme/colors'
import { getHeader, navigationLinks } from 'utils/utils'

const useStyles = makeStyles(() => ({
    customDrop: {
        maxWidth: '95%',
        '& .MuiFilledInput-root': {
            backgroundColor: colors.white,
            '&.Mui-disabled': {
                backgroundColor: colors.lightGray,
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: colors.white,
            },
        },
    },
}))

const PhoneNumberBlockEdit = (): JSX.Element => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()
    const [currentItem, setCurrentItem] = useState<PhoneBlockedData[]>([])
    const { user, searchTerms, customize } = useSelector((appState: AppState) => ({
        user: appState.generalState.user,
        searchTerms: appState.generalState.searchTerms,
        customize: appState.generalState.customize,
    }))
    const { t } = useTranslation()
    if (!user || Object.keys(user ?? {}).length === 0) {
        history.replace('/')
        return <></>
    }
    const numbers = user.contractMetaData.phoneData

    const [selected, setSelected] = useState(0)
    const [successSend, setSuccessSend] = useState(false)
    const [dropDownOpts] = useState(toDropDown(numbers))
    const handleChange = (e: any) => {
        const val = e.target.value as string
        let index = -1
        for (let i = 0; i < dropDownOpts.length; i++) {
            if (val === dropDownOpts[i].value) {
                index = i
                break
            }
        }
        if (index !== -1) {
            setSelected(index)
        }
    }
    const [fetchBlockDetails, { loading }] = useLazyQuery<Query>(GET_PHONEBLOCKED_DATA, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.getPhoneBlockedData) {
                //sort the received data
                const sorted = _.sortBy(data.getPhoneBlockedData, (x) => x.number)
                setCurrentItem(sorted)
            }
        },
    })
    useEffect(() => {
        if (numbers.length > 0) {
            const vars: QueryGetPhoneBlockedDataArgs = { phoneNumber: numbers[selected].phoneNumber }
            fetchBlockDetails({ variables: vars })
        }
    }, [numbers, selected])

    useEffect(() => {
        if (successSend) {
            setSuccessSend(false)
        }
    }, [selected])

    const setBlockItem = (i: number, isBlocked: boolean) => {
        const newItems = [...currentItem]
        newItems[i] = { ...newItems[i], isBlocked: isBlocked }
        setCurrentItem(newItems)
        setSuccessSend(true)
    }

    const [pushPhoneNumberBlockData, { loading: loadingSubmit }] = useMutation<Mutation>(UPDATE_PHONE_BLOCKED_DATA, {
        onCompleted: (data) => {
            if (data.updatePhoneBlockData) {
                setSuccessSend(true)
                enqueueSnackbar(t('notification.sendSuccess'), {
                    variant: 'success',
                })
            }
        },
    })

    const submitData = () => {
        const blocked: string[] = []
        const passable: string[] = []
        for (let i = 0; i < currentItem.length; i++) {
            if (currentItem[i].isBlocked) {
                blocked.push(currentItem[i].number)
            } else {
                passable.push(currentItem[i].number)
            }
        }
        const vars: MutationUpdatePhoneBlockDataArgs = {
            input: {
                phoneNumber: numbers[selected].phoneNumber,
                blocked: blocked,
                passable: passable,
            },
        }
        pushPhoneNumberBlockData({ variables: vars })
    }

    return (
        <ViewWrapper
            header={getHeader(Routes.PhoneNumberBlockEdit, history, searchTerms, customize)}
            navigation={navigationLinks('logged-in', 'myData')}
        >
            <>
                <Typography variant="h1" style={{ marginBottom: 40 }}>
                    {t('pages.phoneNumberBlockEdit.title')}
                </Typography>
                <FieldWrapper text={''}>
                    <Grid container spacing={6} justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="body1">{t('pages.phoneNumberBlockEdit.info1')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">{t('pages.phoneNumberBlockEdit.info2')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.customDrop}>
                                <Dropdown
                                    id={'phoneNumbers'}
                                    disabled={numbers.length === 0}
                                    focused={undefined}
                                    label={t('numberLock.telNumber')}
                                    options={numbers.length > 0 ? dropDownOpts : []}
                                    selectedOption={numbers.length > 0 ? dropDownOpts[selected].label : ''}
                                    handleChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        {!loading && (
                            <Grid item xs={12}>
                                {currentItem.map((blockItem, i) => (
                                    <Grid item key={i}>
                                        <CheckboxComponent
                                            disabled={blockItem.isDisabled}
                                            checked={blockItem.isBlocked}
                                            label={<Typography color="textPrimary">{blockItem.description}</Typography>}
                                            onSelect={() => setBlockItem(i, !blockItem.isBlocked)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                        {loading && (
                            <Grid item xs={12} className="center">
                                <CircularProgress color="primary" />
                            </Grid>
                        )}
                    </Grid>
                </FieldWrapper>
                <Box
                    marginTop={5}
                    marginBottom={5}
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                >
                    <Button variant={'outlined'} color={'default'} onClick={() => history.goBack()}>
                        {t('general.cancel')}
                    </Button>

                    <Button
                        variant={'contained'}
                        color={'primary'}
                        disabled={loadingSubmit || successSend}
                        onClick={submitData}
                    >
                        {!loadingSubmit && t('pages.phoneNumberBlockEdit.submit')}
                        {loadingSubmit && t('pages.phoneNumberBlockEdit.submitLoading')}
                    </Button>
                </Box>
            </>
        </ViewWrapper>
    )
}

export default PhoneNumberBlockEdit
