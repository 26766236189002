import { Box, Button, Grid, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'
import TestIDs from 'utils/TestIDs'

export interface LoginDataProps {
    data: LoginData
    handlePasswordchange: () => void
}

export type LoginData = {
    username: string
    password: string
}

const LoginData = (props: LoginDataProps): JSX.Element => {
    const { t } = useTranslation()
    const { data, handlePasswordchange } = props

    return (
        <>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} md={6}>
                    <Typography variant={'h2'}>{t('pages.myData.login.title')}</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box display="flex" flex={1} justifyContent="flex-end">
                        <Button
                            variant={'outlined'}
                            className={'EditButton'}
                            onClick={handlePasswordchange}
                            startIcon={<EditIcon style={{ width: 20, height: 20 }} />}
                            TouchRippleProps={{ style: { color: colors.main } }}
                            {...TestIDs.GET_PERSONAL_DATA('EDIT_BUTTON')}
                        >
                            {t('pages.myData.login.changePassword')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Box padding={'24px'} marginTop={'35px'} bgcolor={colors.lightGray} alignItems={'flex-end'}>
                <Grid container spacing={2} alignItems="center" justify="flex-end">
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={3}>
                            <Typography style={{ color: colors.midDarkerGray }}>
                                {t('pages.myData.login.normalMode.username')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography>{data.username}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={3}>
                            <Typography style={{ color: colors.midDarkerGray }}>
                                {t('pages.myData.login.normalMode.password')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography>******</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default LoginData
