import gql from 'graphql-tag'

export const GET_PHONEBOOKENTRY = gql`
    query phoneBookEntry($phoneNumber: String!) {
        getPhoneBookEntry(phoneNumber: $phoneNumber) {
            phoneNumber
            electronic
            infoService
            inverse
            printedParticipantDir
            withAddr
        }
    }
`
