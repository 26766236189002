import { Hidden } from '@material-ui/core'
import ContractDocumentTable from 'components/ContractDocumentTable/ContractDocumentTable'
import ContractDocumentTableMobile from 'components/ContractDocumentTableMobile/ContractDocumentTableMobile'
import ViewWrapper from 'components/ViewWrapper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getHeader, navigationLinks } from 'utils/utils'
import useContractState from './useContractState'

export type ContractDocumentOrder = 'date' | 'name' | 'displayName'

const ContractPage = (): JSX.Element => {
    const { t } = useTranslation()
    const history = useHistory()
    const {
        searchTerms,
        page,
        perPage,
        rows,
        items,
        contractDateOrder,
        contractNameOrder,
        contractDisplayNameOrder,
        changeOrder,
        selectOne,
        selectAll,
        selectedDocs,
        downloadItems,
        customize,
    } = useContractState()

    return (
        <ViewWrapper
            header={getHeader(`/${t('url.myContracts')}`, history, searchTerms, customize)}
            navigation={navigationLinks('logged-in', 'contracts')}
        >
            <>
                <Hidden mdUp={true}>
                    <ContractDocumentTableMobile
                        page={page}
                        perPage={perPage}
                        rows={rows}
                        changeOrder={changeOrder}
                        contractDateOrder={contractDateOrder}
                        contractNameOrder={contractNameOrder}
                        contractDisplayNameOrder={contractDisplayNameOrder}
                        items={items}
                        selectedDocs={selectedDocs}
                        selectAll={selectAll}
                        selectOne={selectOne}
                        downloadItems={downloadItems}
                    />
                </Hidden>
                <Hidden smDown={true}>
                    <ContractDocumentTable
                        page={page}
                        perPage={perPage}
                        rows={rows}
                        changeOrder={changeOrder}
                        contractDateOrder={contractDateOrder}
                        contractNameOrder={contractNameOrder}
                        contractDisplayNameOrder={contractDisplayNameOrder}
                        items={items}
                        selectedDocs={selectedDocs}
                        selectAll={selectAll}
                        selectOne={selectOne}
                        downloadItems={downloadItems}
                    />
                </Hidden>
            </>
        </ViewWrapper>
    )
}

export default ContractPage
