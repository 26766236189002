import gql from 'graphql-tag'

export default gql`
    query GetUserData {
        getUserData {
            customerID
            company
            companyLegalForm
            salutation
            title
            firstName
            lastName
            email
            birthdate
            telephone
            mobile
            newsletterEmail
            twoFA
            isCATVCustomer
            catvData
            contractMetaData {
                activeContractIDs
                evn {
                    contractID
                    value
                }
                sendInvoice {
                    contractID
                    sendInvoicePostal
                    sendInvoiceKupo
                }
                activeContracts {
                    contractID
                    contractStart
                    contractEnd
                    possibleContractEnd
                    lastDateForCancellation
                }
                phoneData {
                    id
                    typeID
                    cos
                    clir
                    phoneNumber
                }
            }
            connectionAddress {
                companyName
                companyLegalForm
                salutation
                title
                firstName
                lastName
                street
                houseNumber
                houseNumberExtension
                city
                zipCode
                country
            }
            billingAddress {
                companyName
                companyLegalForm
                salutation
                title
                firstName
                lastName
                street
                houseNumber
                houseNumberExtension
                city
                zipCode
                country
            }
            deliveryAddress {
                companyName
                companyLegalForm
                salutation
                title
                firstName
                lastName
                street
                houseNumber
                houseNumberExtension
                city
                zipCode
                country
            }
            accountHolderAddress {
                companyName
                companyLegalForm
                salutation
                title
                firstName
                lastName
                street
                houseNumber
                houseNumberExtension
                city
                zipCode
                country
            }
            offers
            tips
            bankData {
                currentID
                accountHolder
                iban
                isTransfer
                bic
                creditorID
                Mandatsreferenz
            }
        }
    }
`
