import { useLazyQuery, useMutation } from '@apollo/client'
import {
    Box,
    Button,
    Checkbox,
    createStyles,
    Fade,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    makeStyles,
    Typography,
    useTheme,
} from '@material-ui/core'
import Dropdown from 'components/Dropdown'
import FieldWrapper from 'components/FieldWrapper'
import LoadingSpinner from 'components/LoadingSpinner'
import { SEND_POSTAL_CHANGE_JOB } from 'graphql/mutations/SendPostalChangeJob'
import { GET_CONTRACT_METADATA } from 'graphql/queries/GetContractMetaData'
import { Mutation, MutationSendPostalChangeJobArgs, Query, User } from 'graphql/types'
import { useSnackbar } from 'notistack'
import React, { Dispatch, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { AppState } from 'store/store'
import colors from 'theme/colors'
import TestIDs from 'utils/TestIDs'

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
        },
    }),
)

const ChangeInvoice = (): JSX.Element => {
    //hooks
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()
    const { t } = useTranslation()
    const theme = useTheme()

    //state
    const [selectedContractIndex, setSelectedContractIndex] = useState(0)
    const [success, setSuccess] = useState(false)
    const [state, setState] = useState({
        email: false,
        post: false,
    })
    //destructure due to the checkbox element functionality
    const { email, post } = state

    //reducer actions
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()
    const { user, customize } = useSelector((appState: AppState) => ({
        user: appState.generalState.user,
        customize: appState.generalState.customize,
    }))

    const setUser = useCallback(
        (payload: User) => {
            dispatch({ type: GeneralStateAction.SET_USER, payload })
        },
        [dispatch],
    )

    //gql
    const [postJob, { loading }] = useMutation<Mutation>(SEND_POSTAL_CHANGE_JOB, {
        onCompleted: (data) => {
            if (data.sendPostalChangeJob === false) {
                // handleError
                enqueueSnackbar(t('notification.sendFailed'), { variant: 'error' })
            } else {
                enqueueSnackbar(t('notification.sendSuccess'), { variant: 'success' })
                setSuccess(true)
            }
        },
    })

    const [fetchContractMetaData, { loading: loadingMetaData }] = useLazyQuery<Query>(GET_CONTRACT_METADATA, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (user && data.getContractMetaData) {
                setUser({ ...user, contractMetaData: data.getContractMetaData })
            }
        },
    })

    // const postJobMock = (variables: any) => {
    //     console.log('Mock postJob called with variables:', variables)
    //     setSuccess(true)
    //     enqueueSnackbar(t('notification.sendSuccess'), { variant: 'success' })
    //     return Promise.resolve({ data: 'Mock response data' })
    // }

    // const handleSubmitMock = (): void => {
    //     const selectedContract = user?.contractMetaData.sendInvoice[selectedContractIndex]
    //     if (selectedContract) {
    //         const vars = {
    //             input: {
    //                 optInTextEmail: '',
    //                 optInTextMail: '',
    //                 contractID: selectedContract.contractID,
    //                 email: true,
    //                 mail: false,
    //             },
    //         }

    //         if (selectedContract.sendInvoiceKupo !== state.email) {
    //             vars.input.email = state.email
    //         }
    //         if (selectedContract.sendInvoicePostal !== state.post) {
    //             vars.input.mail = state.post
    //             vars.input.optInTextMail = state.post ? 'Mocked add postal text' : 'Mocked remove postal text'
    //         }

    //         if (vars.input.mail !== undefined || vars.input.email !== undefined) {
    //             postJobMock({ variables: vars })
    //         }
    //     } else {
    //         console.log('wrong contract selected')
    //     }
    // }

    //funcs
    const handleSubmit = (): void => {
        const selectedContract = user?.contractMetaData.sendInvoice[selectedContractIndex]
        if (selectedContract) {
            const vars: MutationSendPostalChangeJobArgs = {
                input: {
                    optInTextEmail: '',
                    optInTextMail: '',
                    contractID: selectedContract.contractID,
                },
            }
            //check if there is a change to send be send
            if (selectedContract.sendInvoiceKupo !== state.email) {
                vars.input.email = state.email
            }
            if (selectedContract.sendInvoicePostal !== state.post) {
                vars.input.mail = state.post
                vars.input.optInTextMail = state.post
                    ? t('pages.changeInvoice.addPostal')
                    : t('pages.changeInvoice.removePostal')
            }

            // check if there is something to post
            // sending only if there was an actual change to the current state on the CRM
            if (vars.input.mail !== undefined || vars.input.email !== undefined) {
                postJob({ variables: vars })
            }
        } else {
            console.log('wrong contract selected')
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked })
        setSuccess(false)
    }

    // side effects

    useEffect(() => {
        if (user && user.contractMetaData.sendInvoice.length > 0) {
            const invoicePostal = user.contractMetaData.sendInvoice[selectedContractIndex].sendInvoicePostal
            const invoiceEmail = user.contractMetaData.sendInvoice[selectedContractIndex].sendInvoiceKupo
            setState({ email: invoiceEmail, post: invoicePostal })
        }
    }, [user, selectedContractIndex, setState])

    useEffect(() => {
        // reload the metaData since we don't know if the user was here already
        // the data is updated ASYNC on the CRM side so when the user re navigates to this site if his previous change was there we need to check if it gone through already
        fetchContractMetaData()
    }, [])

    if (!user || Object.keys(user ?? {}).length === 0) {
        history.replace('/')
        return <></>
    }

    return (
        <>
            <Box marginBottom={5} marginTop={10}>
                <Typography variant={'h2'}>{t('pages.changeInvoice.title')}</Typography>
            </Box>

            {loadingMetaData && <LoadingSpinner loading={loadingMetaData} />}

            <FieldWrapper text={''}>
                <>
                    {!loadingMetaData && (
                        <Grid container item spacing={2} style={{ backgroundColor: theme.palette.primary.light }}>
                            <Grid item xs={12}>
                                <Dropdown
                                    id={'invoiceContracts'}
                                    disabled={false}
                                    focused={undefined}
                                    label={t('formLabels.contractID')}
                                    options={user?.contractMetaData.sendInvoice.map((c, index) => ({
                                        label: c.contractID,
                                        value: index,
                                    }))}
                                    selectedOption={selectedContractIndex}
                                    handleChange={(e) => {
                                        const index = e.target.value as number
                                        setSelectedContractIndex(index)
                                        setSuccess(false)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'body1'}>{t('pages.changeInvoice.content')}</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={classes.root}>
                                    <FormControl required component="fieldset">
                                        <FormGroup>
                                            {customize?.invoice.enableMail && (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={email}
                                                            onChange={handleChange}
                                                            name="email"
                                                            style={{ color: colors.midDarkerGray }}
                                                        />
                                                    }
                                                    label={t('pages.changeInvoice.mailText')}
                                                />
                                            )}

                                            {customize?.invoice.enableMail && (
                                                <Typography variant="caption" style={{ paddingLeft: 32 }}>
                                                    {t('pages.changeInvoice.mailInfo')}
                                                </Typography>
                                            )}
                                            {customize?.invoice.enablePost && (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={post}
                                                            onChange={handleChange}
                                                            name="post"
                                                            style={{ color: colors.midDarkerGray }}
                                                        />
                                                    }
                                                    label={t('pages.changeInvoice.postText')}
                                                />
                                            )}

                                            {customize?.invoice.enablePost && (
                                                <Typography variant="caption" style={{ paddingLeft: 32 }}>
                                                    {t('pages.changeInvoice.postInfo', {
                                                        amount: customize?.invoice.invoicePostCost,
                                                    })}
                                                </Typography>
                                            )}
                                        </FormGroup>
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </>
            </FieldWrapper>
            <Fade in={success} timeout={1000}>
                <Box style={{ padding: 15 }}>
                    <Typography variant={'body1'}>{t('pages.changeInvoice.thanks')}</Typography>
                </Box>
            </Fade>

            <Box marginTop={5} display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'flex-end'}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    {...TestIDs.GET_NEW_SEPA('SUBMIT_BUTTON')}
                    onClick={handleSubmit}
                    disabled={(user === undefined ? true : false) || loading || success}
                >
                    {t('pages.changeInvoice.btn')}
                </Button>
            </Box>
        </>
    )
}

export default ChangeInvoice
