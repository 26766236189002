import { Box, Button, Grid, Link, Typography } from '@material-ui/core'
import FaqTipsList from 'components/FaqTipsList'
import HelpCollapsable from 'components/HelpCollapsable'
import HelpText from 'components/HelpText'
import ViewWrapper from 'components/ViewWrapper'
import { FaqEntry } from 'graphql/types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { getHeader, navigationLinks } from 'utils/utils'
import TestIDs from '../../utils/TestIDs'
import useHelpDetailsState from './useHelpDetailsState'

export interface HelpDetailsProps {
    displayMethod: string
    categoryID: string
    questionID?: string
}

const HelpDetails = (props: RouteComponentProps<HelpDetailsProps>): JSX.Element => {
    const { displayMethod, categoryID, questionID } = props.match.params
    const { t } = useTranslation()
    const history = useHistory()
    const { faqList, tipsList, currentFaqCategory, searchTerms, customize } = useHelpDetailsState(props.match.params)

    return (
        <ViewWrapper
            header={getHeader(Routes.Dashboard, history, searchTerms, customize)}
            navigation={navigationLinks('logged-in', 'help')}
        >
            {currentFaqCategory && (
                <Box display={'flex'} flexDirection={'column'} marginBottom={7.5}>
                    <Box width={1} marginBottom={{ xs: 4, sm: 8 }}>
                        <Typography variant={'h1'} {...TestIDs.GET_HELP_DETAILS('HEADLINE')}>
                            {currentFaqCategory.category}
                        </Typography>
                    </Box>

                    <Box marginBottom={{ xs: 4, sm: 9 }}>
                        {displayMethod === t('url.displayMethod.collapse') && (
                            <HelpCollapsable faqs={currentFaqCategory} selectedFaq={questionID} />
                        )}
                        {displayMethod === t('url.displayMethod.text') && (
                            <>
                                <Box width={1} marginBottom={{ xs: 6, sm: 12 }}>
                                    <Box
                                        width={1}
                                        display={'flex'}
                                        flexDirection={{ xs: 'column', sm: 'row' }}
                                        marginTop={2}
                                        {...TestIDs.GET_HELP_DETAILS('FAQ_LIST')}
                                    >
                                        <Grid container spacing={2}>
                                            {currentFaqCategory.questions.map((faq: FaqEntry) => (
                                                <Grid item xs={12} sm={6} key={faq.id}>
                                                    <Link
                                                        href={`#${Routes.Help}/${displayMethod}/${categoryID}/${faq.id}`}
                                                        underline="always"
                                                        color="textPrimary"
                                                        variant="body1"
                                                        {...TestIDs.GET_HELP_DETAILS('FAQ_LIST', faq.id)}
                                                    >
                                                        {faq.question}
                                                    </Link>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Box>
                                <HelpText faqs={currentFaqCategory} />
                            </>
                        )}
                    </Box>
                    <Grid container spacing={3}>
                        <FaqTipsList
                            faqList={faqList}
                            tipsList={tipsList}
                            categoryToHide={categoryID}
                            displayMethod={displayMethod}
                        />
                    </Grid>
                    <Box marginTop={5} display={'flex'} justifyContent={'flex-end'}>
                        <Button variant={'outlined'} onClick={() => history.push(Routes.Help)}>
                            {t('pages.helpDetails.backToHelpOverview')}
                        </Button>
                    </Box>
                </Box>
            )}
        </ViewWrapper>
    )
}

export default HelpDetails
