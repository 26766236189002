import { Customize } from 'utils/customize/customize'
import { useMutation } from '@apollo/client'
import { ReactivateRegistration } from 'graphql/mutations/ReactivateRegistraion'
import { Mutation, MutationReactivateRegistrationArgs } from 'graphql/types'
import { History } from 'history'
import { Dispatch, SetStateAction, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'

type reactivateRegistrationState = {
    success: boolean
    loading: boolean
    history: History<unknown>
    customerID: string
    setCustomerID: Dispatch<SetStateAction<string>>
    requestNewLink: () => void
    customize: Customize | undefined
}

export const useReactivateRegistration = (): reactivateRegistrationState => {
    const history = useHistory()
    const [success, setSuccess] = useState(false)
    const [customerID, setCustomerID] = useState('')

    const { customize } = useSelector((appState: AppState) => ({
        customize: appState.generalState.customize,
    }))

    const [requestRegistration] = useMutation<Mutation>(ReactivateRegistration, {
        onCompleted: (data) => {
            if (data.reactivateRegistration === true) {
                setSuccess(true)
            }
        },
    })

    const requestNewLink = (): void => {
        if (customerID !== '') {
            const vars: MutationReactivateRegistrationArgs = {
                customerID: customerID,
            }
            requestRegistration({ variables: vars })
        }
    }

    return {
        success,
        loading: false,
        history,
        customerID,
        setCustomerID,
        requestNewLink,
        customize,
    }
}
