import { Box } from '@material-ui/core'
import React from 'react'
import colors from 'theme/colors'

export interface TabPanelProps {
    value: number
    index: number
    children: any
}
export const TabPanel = (props: TabPanelProps): JSX.Element => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ backgroundColor: colors.lightGray, minHeight: 500 }} p={3}>
                    {children}
                </Box>
            )}
        </div>
    )
}
