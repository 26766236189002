import { Box, Button, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import colors from 'theme/colors'

export interface MyDataItemProps {
    title?: string
    helper?: string
    buttonText?: string
    linkTarget?: string
    children?: React.ReactNode
}

const MyDataItem = (props: MyDataItemProps): JSX.Element => {
    const { title, helper, children, buttonText, linkTarget } = props
    const history = useHistory()

    return (
        <Grid
            container
            direction="row"
            justify="center"
            style={{ padding: 24, height: '100%', backgroundColor: colors.lightGray }}
        >
            {title && (
                <Grid item xs={12}>
                    <Typography style={{ marginBottom: 25 }} variant={'h2'}>
                        {title}
                    </Typography>
                </Grid>
            )}
            {helper && (
                <Grid item xs={12}>
                    <Typography variant={'body1'}>{helper}</Typography>
                </Grid>
            )}
            {children && (
                <Grid item xs={12}>
                    {children}
                </Grid>
            )}
            {linkTarget && buttonText && (
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 25 }}>
                        <Button
                            variant={'outlined'}
                            onClick={() => history.push(linkTarget)}
                            TouchRippleProps={{ style: { color: colors.main } }}
                        >
                            {buttonText}
                        </Button>
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}

export default MyDataItem
