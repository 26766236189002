import { Checkbox, FormControlLabel } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import * as React from 'react'
import colors from 'theme/colors'
import { GetTestIDReturn } from 'utils/TestIDs'

export interface CheckboxComponentProps {
    checked: boolean
    disabled?: boolean
    label: React.ReactNode
    onSelect?: () => void
    testIdentifier?: GetTestIDReturn
    customIcon?: React.ReactNode
    customIconChecked?: React.ReactNode
}

const CheckboxComponent = (props: CheckboxComponentProps): JSX.Element => {
    const { onSelect, checked, disabled, label, testIdentifier } = props

    return (
        <FormControlLabel
            {...testIdentifier}
            control={
                <Checkbox
                    onChange={onSelect}
                    checked={checked}
                    disabled={disabled}
                    icon={
                        <CheckBoxOutlineBlankIcon
                            style={{ color: disabled ? colors.lightDarkerGray : colors.midDarkerGray }}
                        />
                    }
                    checkedIcon={
                        <CheckBoxIcon style={{ color: disabled ? colors.lightDarkerGray : colors.midDarkerGray }} />
                    }
                    color="primary"
                />
            }
            label={label}
        />
    )
}

export default CheckboxComponent
