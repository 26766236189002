import { Customize } from 'utils/customize/customize'
import { useLazyQuery } from '@apollo/client'
import { REQUEST_USERNAME } from 'graphql/queries/RequestUsername'
import { Query, QueryRequestUsernameArgs } from 'graphql/types'
import { History } from 'history'
import { Dispatch, SetStateAction, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmail } from 'utils/utils'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'

type forgotUsernameState = {
    success: boolean | null
    loading: boolean
    history: History<unknown>
    email: string
    setEmail: Dispatch<SetStateAction<string>>
    requestUsername: () => void
    customize: Customize | undefined
}

export const useForgotUsername = (): forgotUsernameState => {
    const history = useHistory()
    const [success, setSuccess] = useState<boolean | null>(null)
    const [email, setEmail] = useState('')

    const { customize } = useSelector((appState: AppState) => ({
        customize: appState.generalState.customize,
    }))

    const [fetchUsername] = useLazyQuery<Query>(REQUEST_USERNAME, {
        onCompleted: (data) => {
            if (data.requestUsername === true) {
                setSuccess(true)
            } else {
                setSuccess(false)
            }
        },
    })

    const requestUsername = (): void => {
        if (email !== '' && isEmail(email)) {
            const vars: QueryRequestUsernameArgs = { email }
            fetchUsername({ variables: vars })
        }
    }

    return {
        success,
        loading: false,
        history,
        email,
        setEmail,
        requestUsername,
        customize,
    }
}
