import { isBefore, parse, sub } from 'date-fns'
import { Address, User } from 'graphql/types'
import { uniq } from 'lodash'
import { deleteMissingField, Salutation } from 'utils/utils'

export const dataIsMissing = (data: User): boolean => {
    // NOTE: the user can not edit this here
    //if (data.connectionAddress.zipCode && data.connectionAddress.zipCode.length === 0) return true
    //if (data.connectionAddress.city && data.connectionAddress.city.length === 0) return true
    //if (data.connectionAddress.street && data.connectionAddress.street.length === 0) return true
    //if (data.connectionAddress.houseNumber && data.connectionAddress.houseNumber.length === 0) return true
    if (data.birthdate && data.birthdate.length === 0) return true // Less than 18 yrs
    if (data.email && data.email.length === 0) return true // Check for email's pattern
    if (data.firstName && data.firstName.length === 0) return true
    if (data.lastName && data.lastName.length === 0) return true
    if (data.mobile && data.mobile.length === 0) return true // Check for pattern
    if (data.telephone && data.telephone.length === 0) return true // Check for pattern
    if (data.salutation && data.salutation === Salutation.NONE) return true

    return false
}

export enum editField {
    personalData = 1,
    bankData,
    bankDataWithDiffAccountHolder,
    newsLetterData,
    otherAddrData,
}

export const dataHasErrors = (data: User, editComponent: editField): { status: boolean; errors: string[] } => {
    const errors: string[] = []

    let missingFields: string[] = []
    if (editComponent === editField.personalData) {
        missingFields = [
            'salutation',
            'firstName',
            'lastName',
            //'street',
            //'houseNumber',
            //'zipCode',
            //'city',
            'email',
            'birthdate',
            // 'telephone',
            // 'mobile',
        ]
        if (data.salutation !== '') {
            missingFields = deleteMissingField('salutation', missingFields)
        }

        if (data.firstName.length > 0) {
            missingFields = deleteMissingField('firstName', missingFields)
        }

        if (data.lastName.length > 0) {
            missingFields = deleteMissingField('lastName', missingFields)
        }
        if (data.email.length > 0) {
            const emailRegExp =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            const emailToCheck = data.email
                .replace(/ä/g, 'ae')
                .replace(/ö/g, 'oe')
                .replace(/ü/g, 'ue')
                .replace(/ß/g, 'ss')
            if (emailRegExp.test(emailToCheck)) {
                missingFields = deleteMissingField('email', missingFields)
            } else {
                errors.push('email')
            }
        }

        if (data.birthdate.length > 0) {
            const date = parse(data.birthdate, 'dd.MM.yyyy', new Date())
            if (!isNaN(date.getTime())) {
                const maxDate = sub(new Date(), {
                    years: 18,
                    months: 0,
                    weeks: 0,
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                })
                if (isBefore(date, maxDate)) {
                    missingFields = deleteMissingField('birthdate', missingFields)
                } else {
                    errors.push('birthdate_underage')
                }
            } else {
                errors.push('birthdate_invalidFormat')
            }
        }

        if (data.telephone.length > 0) {
            const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g
            if (phoneRegExp.test(data.telephone)) {
                missingFields = deleteMissingField('telephone', missingFields)
            } else {
                errors.push('telephone')
            }
        }

        if (data.mobile.length > 0) {
            const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g
            if (phoneRegExp.test(data.mobile)) {
                missingFields = deleteMissingField('mobile', missingFields)
            } else {
                errors.push('mobile')
            }
        }
    }

    if (editComponent === editField.newsLetterData) {
        missingFields = ['newsletterEmail']
        if (data.newsletterEmail.length > 0) {
            const emailRegExp =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            const emailToCheck = data.newsletterEmail
                .replace(/ä/g, 'ae')
                .replace(/ö/g, 'oe')
                .replace(/ü/g, 'ue')
                .replace(/ß/g, 'ss')
            if (emailRegExp.test(emailToCheck)) {
                missingFields = deleteMissingField('newsletterEmail', missingFields)
            } else {
                errors.push('email')
            }
        }
    }

    if (editComponent === editField.otherAddrData) {
        missingFields = []
        if (!isAddressEmpty(data.deliveryAddress)) {
            const errDelivery = checkAddress(data.deliveryAddress, '-delivery')
            if (errDelivery.length > 0) {
                errors.push(...errDelivery)
            }
        }
        if (!isAddressEmpty(data.billingAddress)) {
            const errBilling = checkAddress(data.billingAddress, '-billing')
            if (errBilling.length > 0) {
                errors.push(...errBilling)
            }
        }
        if (
            (data.billingAddress.companyName.trim().length === 0 &&
                data.billingAddress.companyLegalForm.trim().length > 0) ||
            (data.billingAddress.companyName.trim().length > 0 &&
                data.billingAddress.companyLegalForm.trim().length === 0)
        ) {
            errors.push('company-billing')
        }
        if (
            (data.deliveryAddress.companyName.trim().length === 0 &&
                data.deliveryAddress.companyLegalForm.trim().length > 0) ||
            (data.deliveryAddress.companyName.trim().length > 0 &&
                data.deliveryAddress.companyLegalForm.trim().length === 0)
        ) {
            errors.push('company-delivery')
        }
    }
    const uniqueErrors = uniq(errors)

    return { status: missingFields.length === 0, errors: uniqueErrors }
}
export const checkAddress = (a: Address, suffix?: string): string[] => {
    const missingFields: string[] = []
    const postfix = suffix ? suffix : ''

    if (a.salutation === '') {
        missingFields.push('salutation' + postfix)
    }
    if (a.firstName === '') {
        missingFields.push('firstName' + postfix)
    }
    if (a.lastName === '') {
        missingFields.push('lastName' + postfix)
    }
    if (a.street === '') {
        missingFields.push('street' + postfix)
    }
    if (a.houseNumber === '') {
        missingFields.push('houseNumber' + postfix)
    }
    if (a.zipCode === '') {
        missingFields.push('zipCode' + postfix)
    }
    if (a.zipCode.length !== 5 && a.zipCode !== '') {
        missingFields.push('zipCode' + postfix)
    }

    if (a.city === '') {
        missingFields.push('city' + postfix)
    }
    if (a.city.length < 2 && a.city !== '') {
        missingFields.push('city' + postfix)
    }

    if (a.country === '' || a.country.length < 4) {
        missingFields.push('country' + postfix)
    }

    return missingFields
}

export const isAddressEmpty = (a: Address): boolean => {
    for (const [key, value] of Object.entries(a)) {
        if (key === '__typename') {
            continue
        }

        if (value !== '') {
            return false
        }
    }
    return true
}

export const isNewAddress = (a: Address, b: Address): boolean => {
    // a is new when b is empty and a is filled
    return isAddressEmpty(b) && !isAddressEmpty(a)
}
