import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core'
import FieldWrapper from 'components/FieldWrapper'
import ViewWrapper from 'components/ViewWrapper'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from 'store/store'
import { Routes } from 'routes/routes'
import TestIDs from 'utils/TestIDs'
import { getHeader, navigationLinks } from 'utils/utils'

const NewSepa = (): JSX.Element => {
    const history = useHistory()
    const { t } = useTranslation()
    const theme = useTheme()
    const anchorRef = useRef<HTMLAnchorElement>(null)

    const { customize } = useSelector((appState: AppState) => ({
        customize: appState.generalState.customize,
    }))

    const handleSubmit = () => {
        if (anchorRef.current !== null) {
            //DOWNLOAD pdf
            anchorRef.current.click()
        }
    }
    return (
        <ViewWrapper
            header={getHeader(Routes.Dashboard, history, [], customize)}
            navigation={navigationLinks('logged-in', 'myData')}
        >
            <>
                <Box marginBottom={5}>
                    <Typography variant={'h2'}>{t('pages.newSEPA.title')}</Typography>
                </Box>

                <FieldWrapper text={''}>
                    <Grid container item spacing={2} style={{ backgroundColor: theme.palette.primary.light }}>
                        <Grid item xs={12}>
                            <Typography variant={'body1'}>{t('pages.newSEPA.helper0')}</Typography>
                            <Typography variant={'body1'}>{t('pages.newSEPA.helper1')}</Typography>
                            <Typography variant={'body1'}>{t('pages.newSEPA.helper2')}</Typography>
                            {t('pages.newSEPA.helper3') !== 'pages.newSEPA.helper3' && (
                                <Typography
                                    variant={'body1'}
                                    dangerouslySetInnerHTML={{ __html: t('pages.newSEPA.helper3') }}
                                />
                            )}
                            {t('pages.newSEPA.helper4') !== 'pages.newSEPA.helper4' && (
                                <Typography
                                    variant={'body1'}
                                    dangerouslySetInnerHTML={{ __html: t('pages.newSEPA.helper4') }}
                                />
                            )}
                            <Typography variant={'body1'} style={{ paddingTop: 20, fontWeight: 800 }}>
                                {t('pages.newSEPA.addrPost')}
                            </Typography>
                            <Typography
                                variant={'body1'}
                                dangerouslySetInnerHTML={{ __html: t('pages.newSEPA.adress') }}
                            />

                            {t('pages.newSEPA.addrEmail') !== 'pages.newSEPA.addrEmail' && (
                                <>
                                    <Typography variant={'body1'} style={{ paddingTop: 20, fontWeight: 800 }}>
                                        {t('pages.newSEPA.addrEmail')}
                                    </Typography>
                                    <Typography
                                        variant={'body1'}
                                        dangerouslySetInnerHTML={{ __html: t('pages.newSEPA.mailaddress') }}
                                    />
                                </>
                            )}

                            <Typography variant={'body1'} style={{ paddingTop: 20 }}>
                                <Typography
                                    variant={'body1'}
                                    dangerouslySetInnerHTML={{ __html: t('pages.newSEPA.footer') }}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </FieldWrapper>

                <Box
                    marginTop={5}
                    marginBottom={5}
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                >
                    <Button
                        variant={'outlined'}
                        color={'default'}
                        {...TestIDs.GET_NEW_SEPA('CANCEL_BUTTON')}
                        onClick={() => history.goBack()}
                    >
                        {t('general.back')}
                    </Button>

                    <Button
                        variant={'contained'}
                        color={'primary'}
                        {...TestIDs.GET_NEW_SEPA('SUBMIT_BUTTON')}
                        onClick={handleSubmit}
                    >
                        <a
                            style={{
                                pointerEvents: 'none',
                                textDecoration: 'none !important',
                                color: 'white',
                            }}
                            ref={anchorRef}
                            role="button"
                            href="/customize/SEPA-Lastschriftmandat.pdf"
                            download="SEPA-Lastschriftmandat.pdf"
                        >
                            {t('pages.newSEPA.savePDF')}
                        </a>
                    </Button>
                </Box>
            </>
        </ViewWrapper>
    )
}

export default NewSepa
