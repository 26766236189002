import { Box, Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'
import TestIDs from 'utils/TestIDs'
import { validateEmail } from 'utils/utils'

export interface LoginEditProps {
    userID: string
    email: string
    handleEmail: (email: string) => void
    handlePasswordReset: () => void
    cancel: () => void
}

const useStyles = makeStyles({
    disabledTextField: {
        backgroundColor: colors.lightBorderGray,
        borderRadius: 5,
    },
    TextFieldCustom: {
        backgroundColor: colors.white,
        borderRadius: 5,
    },
})

const LoginEdit = (props: LoginEditProps): JSX.Element => {
    const { userID, email, handleEmail, handlePasswordReset, cancel } = props
    const { t } = useTranslation()
    const classes = useStyles()
    const [internalEmail, setInternalEmail] = useState<string>(email)
    const [errorEmail, setErrorEmail] = useState(false)
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const isEmailCorrect = validateEmail(e.target.value)
        setInternalEmail(e.target.value)
        setErrorEmail(!isEmailCorrect)
    }

    const [pwChangeRequest, setPwChangeRequest] = useState(false)
    return (
        <>
            <Box marginTop={5} padding={5} style={{ backgroundColor: colors.lightGray }}>
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth={true}
                            label={t('formLabels.userNameLong')}
                            variant={'filled'}
                            disabled
                            InputProps={{
                                classes: {
                                    input: classes.disabledTextField,
                                },
                            }}
                            value={userID}
                            {...TestIDs.GET_LOGIN_EDIT('ID')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth={true}
                            label={t('formLabels.email')}
                            variant={'filled'}
                            value={internalEmail}
                            onChange={handleEmailChange}
                            error={errorEmail}
                            InputProps={{
                                classes: {
                                    input: classes.TextFieldCustom,
                                },
                            }}
                            {...TestIDs.GET_LOGIN_EDIT('MAIL')}
                        />
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography variant="h3">{t('pages.loginEdit.password')}</Typography>
                        <Typography variant="subtitle1">{t('pages.loginEdit.passwordHelper')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth={true}
                            variant={'contained'}
                            color={'primary'}
                            {...TestIDs.GET_LOGIN_EDIT('PW_BUTTON')}
                            onClick={() => {
                                setPwChangeRequest(true)
                                handlePasswordReset()
                            }}
                        >
                            {t('pages.loginEdit.pwBtn')}
                        </Button>
                        {pwChangeRequest === true && (
                            <Typography variant="h2" style={{ textAlign: 'center', paddingTop: 10 }}>
                                {t('pages.loginEdit.sendPwRequest')}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box
                marginTop={5}
                marginBottom={5}
                display={'flex'}
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent={'space-between'}
            >
                <Button
                    variant={'outlined'}
                    color={'default'}
                    {...TestIDs.GET_LOGIN_EDIT('CANCEL_BUTTON')}
                    onClick={() => cancel()}
                >
                    {t('general.cancel')}
                </Button>

                <Button
                    variant={'contained'}
                    color={'primary'}
                    {...TestIDs.GET_LOGIN_EDIT('SUBMIT_BUTTON')}
                    onClick={() => handleEmail(internalEmail)}
                    disabled={errorEmail}
                >
                    {t('general.save')}
                </Button>
            </Box>
        </>
    )
}

export default LoginEdit
