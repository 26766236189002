import { Box, Button, Divider, Grid, Link, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import CustomizedAccordions from 'components/CustomAccordion/CustomAccordion'
import { format } from 'date-fns'
import { InetSpeed, Package } from 'graphql/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'
//import { formatCurrency } from 'utils/formatCurrency'
import CancelIcon from '@material-ui/icons/Cancel'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
    root: {
        padding: '7px 16px !important',
        color: colors.lightDarkerGray,
        border: `2px solid ${colors.lightDarkerGray}`,
        backgroundColor: colors.white,
    },
})

export interface ProductTabProps {
    contractID: string
    productInfo: Package
    datasheetLink: string
}
const blackHeavyText: React.CSSProperties = {
    color: colors.black,
    hyphens: 'manual',
    fontWeight: 700,
    height: 48,
    marginBottom: 25,
    overflowWrap: 'break-word',
}

const overflowHidden: React.CSSProperties = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    height: '100%',
}
export const ProductTab = (props: ProductTabProps): JSX.Element => {
    const { productInfo, datasheetLink, contractID } = props
    const history = useHistory()
    const { t } = useTranslation()
    const start = format(new Date(productInfo.periodStart), 'dd.MM.yyyy')
    let end = ''
    if (productInfo.periodEnd != '') {
        end = format(new Date(productInfo.periodEnd), 'dd.MM.yyyy')
    }
    const nextCancellation = format(new Date(productInfo.earliestCancellationDate), 'dd.MM.yyyy')
    const theme = useTheme()
    const isSmallLayout = useMediaQuery(theme.breakpoints.down('sm'))

    const dividerHeight = 30
    const leftMD = 4
    const rightMD = 8
    let iconOffset = 16
    if (isSmallLayout) {
        iconOffset = 24
    }
    const hideIcon = (inetSpeed: InetSpeed | null | undefined, phoneNumbers: string[] | undefined | null): boolean => {
        if (inetSpeed) {
            return false
        }
        if (phoneNumbers && phoneNumbers.length > 0) {
            return false
        }
        return true
    }
    const classes = useStyles()
    return (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        className={classes.root}
                        onClick={() => history.push('/cancelContract/' + contractID)}
                    >
                        <CancelIcon />
                        {t('cancelContract1')}
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={leftMD}>
                <Typography style={{ color: colors.midDarkerGray }} variant="body1">
                    {t('productTab.period')}:
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={rightMD}>
                <Typography variant="body1">
                    {start} - {end === '' ? '...' : end}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ minHeight: dividerHeight }}></Grid>
            <Grid item xs={12} sm={6} md={leftMD}>
                <Typography style={{ color: colors.midDarkerGray }} variant="body1">
                    {t('productTab.nextCancellation')}:
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={rightMD}>
                <Typography variant="body1">{nextCancellation}</Typography>
            </Grid>
            <Grid item xs={12} style={{ minHeight: dividerHeight }}></Grid>

            {datasheetLink !== '' && (
                <Grid item xs={12} sm={6} md={leftMD}>
                    <Typography style={{ color: colors.midDarkerGray }} variant="body1">
                        {t('productTab.serviceDescription')}:
                    </Typography>
                </Grid>
            )}
            {datasheetLink !== '' && (
                <Grid item xs={12} sm={6} md={rightMD}>
                    <Link href={datasheetLink} target="_blank">
                        <Typography variant="body1">{t('productTab.linkToServiceDescription')}</Typography>
                    </Link>
                </Grid>
            )}
            <Grid item xs={12} style={{ minHeight: dividerHeight }}></Grid>
            <Grid item xs={12}>
                <Typography variant="h2">{t('productTab.bookedPackages')}</Typography>
            </Grid>
            <Grid item xs={12} style={{ minHeight: dividerHeight }}></Grid>
            {productInfo?.Products.map((p, i) => (
                <Grid container justify="center" alignItems="center" key={p.name + i}>
                    <Grid item xs={12}>
                        {i === 0 && <Divider />}
                        <CustomizedAccordions
                            hideIcon={hideIcon(p.inetSpeeds, p.phoneNumbers)}
                            header={
                                <>
                                    <Grid item xs={12} sm={6} md={leftMD}>
                                        <Typography style={{ color: colors.midDarkerGray }} variant="body1">
                                            {t('productTab.package')}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={rightMD}>
                                        <Typography variant="body1" style={{ paddingLeft: iconOffset }}>
                                            {p.name}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            sub={
                                <Grid container justify="center" alignItems="flex-start">
                                    {/*
                                    NOTE: commented out the price for now until we know how to display the price correctly
                                    NOTE: DO NOT DELETE PLS WILL BE used when we get the price
                                    <Grid item xs={12} sm={6} md={leftMD}>
                                        <Typography style={{ color: colors.midDarkerGray }} variant="body1">
                                            {t('productTab.monthlyPrice')}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={rightMD}>
                                        <Typography variant="body1">{formatCurrency(p.monthlyPrice)}</Typography>
                                    </Grid>
                                */}

                                    {p.inetSpeeds && (
                                        <Grid
                                            container
                                            justify="center"
                                            alignItems="flex-start"
                                            direction="row"
                                            style={{ marginTop: 10 }}
                                        >
                                            <Grid item xs={4}>
                                                <Typography
                                                    style={blackHeavyText}
                                                    variant="body1"
                                                    dangerouslySetInnerHTML={{ __html: t('productTab.dataTransfer') }}
                                                />
                                                <Typography variant="body1" style={overflowHidden}>
                                                    {t('productTab.max')}
                                                </Typography>
                                                <Typography variant="body1" style={overflowHidden}>
                                                    {t('productTab.std')}
                                                </Typography>
                                                <Typography variant="body1" style={overflowHidden}>
                                                    {t('productTab.min')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography style={blackHeavyText} variant="body1">
                                                    {t('productTab.download')}
                                                </Typography>
                                                <Typography variant="body1">{p.inetSpeeds.DownMax}</Typography>
                                                <Typography variant="body1">{p.inetSpeeds.DownStd}</Typography>
                                                <Typography variant="body1">{p.inetSpeeds.DownMin}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography style={blackHeavyText} variant="body1">
                                                    {t('productTab.upload')}
                                                </Typography>
                                                <Typography variant="body1">{p.inetSpeeds.UpMax}</Typography>
                                                <Typography variant="body1">{p.inetSpeeds.UpStd}</Typography>
                                                <Typography variant="body1">{p.inetSpeeds.UpMin}</Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {p.phoneNumbers && p.phoneNumbers.length > 0 && (
                                        <Grid
                                            container
                                            justify="flex-start"
                                            alignItems="flex-start"
                                            direction="row"
                                            style={{ marginTop: 10 }}
                                        >
                                            <Grid item xs={12} sm={6} md={leftMD}>
                                                <Typography style={{ color: colors.midDarkerGray }} variant="body1">
                                                    {t('productTab.phoneNumbers')}:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={rightMD}>
                                                {p.phoneNumbers.map((pp, ii) => (
                                                    <Typography variant="body1" key={ii + pp}>
                                                        {pp}
                                                    </Typography>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            }
                        />
                        {p.Options?.length === 0 && <Divider />}
                    </Grid>
                    {p.Options?.map((opt, i) => (
                        <Grid item xs={12} key={opt.name + i}>
                            {i === 0 && <Divider />}
                            <CustomizedAccordions
                                hideIcon={true}
                                header={
                                    <>
                                        <Grid item xs={12} sm={6} md={leftMD}>
                                            <Typography style={{ color: colors.midDarkerGray }} variant="body1">
                                                {t('productTab.opt')}:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={rightMD}>
                                            <Typography variant="body1" style={{ paddingLeft: iconOffset }}>
                                                {opt.name}
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                                sub={
                                    <>
                                        {/*
                                        <Grid item xs={12} sm={6} md={leftMD}>
                                            <Typography style={{ color: colors.midDarkerGray }} variant="body1">
                                                {t('productTab.monthlyPrice')}:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={rightMD}>
                                            <Typography variant="body1">{formatCurrency(opt.monthlyPrice)}</Typography>
                                        </Grid>

                                    */}
                                    </>
                                }
                            />
                            <Divider />
                        </Grid>
                    ))}
                </Grid>
            ))}
            <Grid item xs={12} style={{ minHeight: dividerHeight }}></Grid>
            <Grid item xs={12}>
                {productInfo.Hardware.length != 0 && <Typography variant="h2">{t('productTab.hardware')}</Typography>}
            </Grid>
            <Grid item xs={12} style={{ minHeight: dividerHeight }}></Grid>
            {productInfo?.Hardware.map((h, i) => (
                <Grid container justify="center" alignItems="center" spacing={1} key={h.name + i}>
                    <Grid item xs={12}>
                        <Divider />
                        <CustomizedAccordions
                            hideIcon={true}
                            header={
                                <>
                                    <Grid item xs={12} sm={6} md={leftMD}>
                                        <Typography style={{ color: colors.midDarkerGray }} variant="body1">
                                            {t('productTab.package')}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={rightMD}>
                                        <Typography variant="body1" style={{ paddingLeft: iconOffset }}>
                                            {h.name}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                            sub={
                                <>
                                    {/*
                                    <Grid item xs={12} sm={6} md={leftMD}>
                                        <Typography style={{ color: colors.midDarkerGray }} variant="body1">
                                            {t('productTab.monthlyPrice')}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={rightMD}>
                                        <Typography variant="body1">{formatCurrency(h.monthlyPrice)}</Typography>
                                    </Grid>
                                */}
                                </>
                            }
                        />
                        {productInfo && i === productInfo.Hardware.length - 1 && <Divider />}
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12} style={{ minHeight: dividerHeight }}></Grid>
        </Grid>
    )
}

export default ProductTab
