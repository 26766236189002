import { useLazyQuery } from '@apollo/client'
import { Box, Button, Grid, makeStyles, Typography, CircularProgress } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import EditIcon from '@material-ui/icons/Edit'
import Dropdown from 'components/Dropdown'
import { GET_PHONEBLOCKED_DATA } from 'graphql/queries/GetPhoneBlockedData'
import { PhoneBlockedData, Query, QueryGetPhoneBlockedDataArgs } from 'graphql/types'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import colors from 'theme/colors'
import { DropdownOption } from 'utils/utils'

const useStyles = makeStyles(() => ({
    customDrop: {
        maxWidth: '95%',
        '& .MuiFilledInput-root': {
            backgroundColor: colors.white,
            '&.Mui-disabled': {
                backgroundColor: colors.lightGray,
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: colors.white,
            },
        },
    },
}))

export interface NumberBlockProps {
    numbers: PhoneNumberContext[]
}

export interface PhoneNumberContext {
    phoneNumber: string
    cos: string
}

export interface CosTranslation {
    mobile: boolean
    foreignCountry: boolean
    televoting: boolean
}

export const toDropDown = (a: PhoneNumberContext[]): DropdownOption[] => {
    const r: DropdownOption[] = []
    for (let i = 0; i < a.length; i++) {
        r.push({ label: a[i].phoneNumber, value: a[i].phoneNumber })
    }
    return r
}
const NumberBlock = (props: NumberBlockProps): JSX.Element => {
    const classes = useStyles()
    const history = useHistory()
    const { numbers } = props
    const [selected, setSelected] = useState(0)
    const [currentItem, setCurrentItem] = useState<PhoneBlockedData[]>([])

    const [dropDownOpts] = useState(toDropDown(numbers))
    const { t } = useTranslation()
    const handleChange = (e: any) => {
        const val = e.target.value as string
        let index = -1
        for (let i = 0; i < dropDownOpts.length; i++) {
            if (val === dropDownOpts[i].value) {
                index = i
                break
            }
        }
        if (index !== -1) {
            setSelected(index)
        }
    }

    const [fetchBlockDetails, { loading }] = useLazyQuery<Query>(GET_PHONEBLOCKED_DATA, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.getPhoneBlockedData) {
                //sort the received data
                const sorted = _.sortBy(data.getPhoneBlockedData, (x) => x.number)
                setCurrentItem(sorted)
            }
        },
    })
    useEffect(() => {
        if (numbers.length > 0) {
            const vars: QueryGetPhoneBlockedDataArgs = { phoneNumber: numbers[selected].phoneNumber }
            fetchBlockDetails({ variables: vars })
        }
    }, [numbers, selected])

    return (
        <Grid container justify="flex-start">
            <Grid item xs={12} sm={8}>
                <Typography variant={'body1'}>{t('numberLock.helper')}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Box display="flex" flex={1} justifyContent="flex-end">
                    <Button
                        variant={'outlined'}
                        className={'EditButton'}
                        disabled={numbers.length === 0}
                        startIcon={<EditIcon style={{ width: 20, height: 20 }} />}
                        TouchRippleProps={{ style: { color: colors.main } }}
                        onClick={() => history.push(Routes.PhoneNumberBlockEdit)}
                    >
                        {t('numberLock.change')}
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 50 }}>
                <Box className={classes.customDrop}>
                    <Dropdown
                        id={'phoneNumbers'}
                        disabled={numbers.length === 0}
                        focused={undefined}
                        label={t('numberLock.telNumber')}
                        options={numbers.length > 0 ? dropDownOpts : []}
                        selectedOption={numbers.length > 0 ? dropDownOpts[selected].label : ''}
                        handleChange={handleChange}
                    />
                </Box>
            </Grid>
            {!loading && (
                <Grid item xs={12} style={{ marginTop: 30 }}>
                    <Grid container spacing={2}>
                        {currentItem.map((blockItem, i) => (
                            <React.Fragment key={i}>
                                <Grid item xs={1}>
                                    {(blockItem.isBlocked || blockItem.isDisabled) && (
                                        <CheckIcon style={{ color: '#979797' }} />
                                    )}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant="body1">{blockItem.description}</Typography>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
            )}
            {loading && (
                <Grid item xs={12} className="center" style={{ marginTop: 30 }}>
                    <CircularProgress color="primary" />
                </Grid>
            )}
        </Grid>
    )
}

export default NumberBlock
