import { Box, Button, Divider, Grid, Typography } from '@material-ui/core'
import FaqTipsList from 'components/FaqTipsList'
import TipsTricksList from 'components/TipsTricksList'
import ViewWrapper from 'components/ViewWrapper'
import useTipsOverviewState from 'pages/TipsOverview/useTipsOverviewState'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import TestIDs from 'utils/TestIDs'
import { getHeader, navigationLinks } from 'utils/utils'

const TipsOverview = (): JSX.Element => {
    const { t } = useTranslation()
    const history = useHistory()

    const { areFaqsCollapsable, faqList, tipsList, searchTerms, customize } = useTipsOverviewState()

    return (
        <ViewWrapper
            header={getHeader(Routes.Dashboard, history, searchTerms, customize)}
            navigation={navigationLinks('logged-in', 'help')}
        >
            <Box display={'flex'} flexDirection={'column'}>
                <TipsTricksList headline={t('pages.tipsOverview.title')} tipsTricksList={tipsList} />
                <Divider />
                <Box width={1} marginTop={{ xs: 5, sm: 10 }}>
                    <Typography variant={'h2'} {...TestIDs.GET_TIPS_OVERVIEW('HEADLINE')}>
                        {t('pages.tipsOverview.faq.title')}
                    </Typography>
                </Box>
                <Box
                    width={1}
                    display={'flex'}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    marginTop={5}
                    marginBottom={5}
                >
                    <Grid container spacing={3}>
                        <FaqTipsList
                            categoryToHide={'tips'}
                            faqList={faqList}
                            tipsList={[]}
                            displayMethod={
                                areFaqsCollapsable ? t('url.displayMethod.collapse') : t('url.displayMethod.text')
                            }
                        />
                    </Grid>
                </Box>
                <Box
                    marginTop={5}
                    marginBottom={5}
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={'flex-end'}
                >
                    <Button
                        variant={'outlined'}
                        onClick={() => history.push(Routes.Help)}
                        {...TestIDs.GET_TIPS_OVERVIEW('BACK_TO_HELP_OVERVIEW')}
                    >
                        {t('pages.tipsOverview.backToHelpOverview')}
                    </Button>
                </Box>
            </Box>
        </ViewWrapper>
    )
}

export default TipsOverview
