import { ApolloProvider } from '@apollo/client'
import DateFnsUtils from '@date-io/date-fns'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useKeycloak } from '@react-keycloak/web'
import ErrorDialog from 'components/ErrorDialog1'
import LoadingSpinner from 'components/LoadingSpinner'
import PhoneNumberBlockEdit from 'components/PhoneNumberBlockEdit/PhoneNumberBlockEdit'
import deLocale from 'date-fns/locale/de'
import { ErrorConfig } from 'graphql/types'
import { SnackbarProvider } from 'notistack'
import BankDataEdit from 'pages/BankDataEdit/BankDataEdit'
import CancelContract from 'pages/CancelContract'
import CancelContractConfirmation from 'pages/CancelContractConfirmation/CancelContractConfirmation/CancelContractConfirmation'
import ContractOverview from 'pages/ContractOverview'
import ContractPage from 'pages/ContractPage'
import DashboardPage from 'pages/DashboardPage'
import ForgotUsernamePage from 'pages/ForgotUsernamePage/ForgotUsernamePage'
import HelpDetails from 'pages/HelpDetails'
import HelpOverview from 'pages/HelpOverview'
import LoginEditPage from 'pages/LoginEditPage'
import MyDataPage from 'pages/MyDataPage'
import MyInvoicesPage from 'pages/MyInvoices'
import NewSepa from 'pages/NewSepa/NewSepa'
import OtherAddrEdit from 'pages/OtherAddrEdit'
import PersonalDataEdit from 'pages/PersonalDataEdit/PersonalDataEdit'
import PhoneBookEntryPage from 'pages/PhoneBookEntryPage'
import PhoneNumberDisplayEditPage from 'pages/PhoneNumberDisplayEditPage'
import ReactivateRegistration from 'pages/ReactivateRegistration'
import TipsDetails from 'pages/TipDetails'
import TipsOverview from 'pages/TipsOverview'
import ValidateRegistration from 'pages/ValidateRegistration'
import React, { Dispatch, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Routes } from 'routes/routes'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { Customize } from 'utils/customize/customize'
import getEnvConfig from 'utils/getEnvConfig'
import 'utils/i18n'
import PrivateRoute from 'utils/PrivateRoute'
import { ModifiedTheme } from 'theme/theme'
import { useApolloClient } from './utils/useApolloClient'

function App(): JSX.Element {
    const envConfig = getEnvConfig()
    const graphQLServerURI =
        process.env.NODE_ENV !== 'production'
            ? `http://${envConfig.Domain}:${envConfig.HTTPListen}/graphql`
            : '/graphql'
    const [error, setError] = React.useState<ErrorConfig>()
    const { keycloak, initialized } = useKeycloak()
    const apolloClient = useApolloClient(graphQLServerURI, setError, keycloak)

    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()

    // load customize config
    useEffect(() => {
        //@ts-expect-error own declaration
        const dataFromJS: Customize = window.customize
        dispatch({ type: GeneralStateAction.SET_GENERAL_STATE_PARTIAL, payload: { customize: dataFromJS } })
        document.title = dataFromJS.general.title
    }, [])

    if (!initialized) {
        return <LoadingSpinner loading={true} />
    }

    return (
        <>
            {apolloClient && (
                <ApolloProvider client={apolloClient}>
                    <HashRouter>
                        <ThemeProvider theme={ModifiedTheme}>
                            <CssBaseline>
                                {error && (
                                    <ErrorDialog
                                        errorConfig={error}
                                        onClick={() => {
                                            window.location.href = '#/dashBoard'
                                            setError(undefined)
                                        }}
                                    />
                                )}
                                {!error && (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                        <SnackbarProvider maxSnack={3}>
                                            <Switch>
                                                <Route
                                                    exact
                                                    path={Routes.ForgotUsername}
                                                    component={ForgotUsernamePage}
                                                />
                                                <Route
                                                    exact
                                                    path={Routes.ReactivateRegistration}
                                                    component={ReactivateRegistration}
                                                />
                                                <Route
                                                    exact
                                                    path={Routes.ValidateRegistration}
                                                    component={ValidateRegistration}
                                                />
                                                <PrivateRoute exact path={Routes.NewSepa} component={NewSepa} />
                                                <PrivateRoute exact path={Routes.Dashboard} component={DashboardPage} />
                                                <PrivateRoute
                                                    exact
                                                    path={Routes.PhoneBookEntryEdit}
                                                    component={PhoneBookEntryPage}
                                                />

                                                <PrivateRoute
                                                    exact
                                                    path={Routes.PhoneNumberDisplayEdit}
                                                    component={PhoneNumberDisplayEditPage}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={Routes.PhoneNumberBlockEdit}
                                                    component={PhoneNumberBlockEdit}
                                                />
                                                <PrivateRoute exact path={Routes.MyData} component={MyDataPage} />
                                                <PrivateRoute exact path={Routes.Invoices} component={MyInvoicesPage} />
                                                <PrivateRoute exact path={Routes.Help} component={HelpOverview} />
                                                <PrivateRoute exact path={Routes.HelpDetails} component={HelpDetails} />
                                                <PrivateRoute exact path={Routes.Tips} component={TipsOverview} />
                                                <PrivateRoute exact path={Routes.TipsID} component={TipsDetails} />
                                                <PrivateRoute
                                                    exact
                                                    path={Routes.PersonalDataEdit}
                                                    component={PersonalDataEdit}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={Routes.BankDataEdit}
                                                    component={BankDataEdit}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={Routes.OtherAddrEdit}
                                                    component={OtherAddrEdit}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={Routes.ContractDocs}
                                                    component={ContractPage}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={Routes.ContractOverview}
                                                    component={ContractOverview}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={Routes.CancelContract}
                                                    component={CancelContract}
                                                />
                                                <PrivateRoute
                                                    exact
                                                    path={Routes.CancelContractConfirmation}
                                                    component={CancelContractConfirmation}
                                                />
                                                <PrivateRoute exact path={Routes.LoginEdit} component={LoginEditPage} />
                                                <Route>
                                                    <Redirect to={Routes.Dashboard} />
                                                </Route>
                                                <PrivateRoute exact path={'/'} component={DashboardPage} />
                                            </Switch>
                                        </SnackbarProvider>
                                    </MuiPickersUtilsProvider>
                                )}
                            </CssBaseline>
                        </ThemeProvider>
                    </HashRouter>
                </ApolloProvider>
            )}
        </>
    )
}

export default App
