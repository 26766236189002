import { Customize } from 'utils/customize/customize'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { usePagination, UsePaginationItem } from '@material-ui/lab'
import { useKeycloak } from '@react-keycloak/web'
import { SearchOption } from 'components/SearchBar/SearchBar'
import { SUM_BILLS } from 'graphql/queries/GetSumForBills'
import { SEARCH_DOCS } from 'graphql/queries/SearchDocs'
import { Doc, Query, QueryGetSumForBillsArgs, QuerySearchDocsArgs, SumBill } from 'graphql/types'
import _ from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'
import { dlDoc } from 'utils/dldoc'
import { getNewOrder, SortOrder } from 'utils/utils'

export type InvoiceOrderField = 'invoiceTarget' | 'docType'

interface useMyInvoicesStateReturn {
    invoices: Doc[]
    pageSize: number
    selectedInvoices: number[]
    setSelectedInvoices: Dispatch<SetStateAction<number[]>>
    loading: boolean
    searchTerms: SearchOption[]
    downloadItems: (vi: number[]) => void
    setInvoices: Dispatch<SetStateAction<Doc[]>>
    items: UsePaginationItem[]
    selectAll: (e: React.ChangeEvent<HTMLInputElement>) => void
    selectOne: (index: number) => void
    changeOrder: (field: InvoiceOrderField) => void
    dateOrder: SortOrder
    docTypeOrder: SortOrder
    page: number
    billAmount: SumBill[]
    billIsLoading: boolean
    customize: Customize | undefined
}

const useMyInvoicesState = (): useMyInvoicesStateReturn => {
    const [docs, setDocs] = useState<Doc[]>([])
    const [selectedInvoices, setSelectedInvoices] = useState<number[]>([])
    const { keycloak } = useKeycloak()
    const token = keycloak.token ? keycloak.token : ''
    const pageSize = 10
    const [alreadySearched, setAlreadySearched] = useState<string[]>([])

    const { searchTerms, evnList, customize } = useSelector((appState: AppState) => ({
        searchTerms: appState.generalState.searchTerms,
        customize: appState.generalState.customize,
        evnList: appState.generalState.user?.contractMetaData.evn,
    }))

    const [page, setPage] = useState<number>(1)
    const [dateOrder, setDateOrder] = useState<SortOrder>('desc')
    const [docTypeOrder, setDocTypeOrder] = useState<SortOrder>(undefined)

    const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number): void => {
        // reset selection on page change
        setSelectedInvoices([])
        setPage(newPage)
    }

    const { items } = usePagination({
        count:
            docs.length % pageSize === 0 ? Math.floor(docs.length / pageSize) : Math.floor(docs.length / pageSize) + 1,
        page: page,
        onChange: handleChangePage,
    })

    const selectAll = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.checked) {
            // set soft limit to download 10 invoices at a time or the invoicesPerPage number
            const newSelecteds = docs.slice((page - 1) * pageSize, pageSize * page).map((_, i) => i)
            setSelectedInvoices(newSelecteds)
            return
        }
        setSelectedInvoices([])
    }

    const selectOne = (index: number) => {
        let k = -1
        for (let i = 0; i < selectedInvoices.length; i++) {
            if (selectedInvoices[i] === index) {
                k = i
                break
            }
        }
        if (k === -1) {
            selectedInvoices.push(index)
        } else {
            selectedInvoices.splice(k, 1)
        }
        setSelectedInvoices([...selectedInvoices])
    }

    const changeOrder = (field: InvoiceOrderField, order?: SortOrder) => {
        if (field === 'invoiceTarget') {
            let newDateOrder: SortOrder = undefined
            if (order) {
                newDateOrder = order
            } else {
                newDateOrder = getNewOrder(dateOrder)
            }
            const sorted = _.orderBy(docs, ['invoiceTarget'], [newDateOrder ? newDateOrder : false])
            setDateOrder(newDateOrder)
            setDocs(sorted)
            setSelectedInvoices([])
            return
        }
        if (field === 'docType') {
            let newDocTypeOrder: SortOrder = undefined
            if (order) {
                newDocTypeOrder = order
            } else {
                newDocTypeOrder = getNewOrder(dateOrder)
            }
            const sorted = _.orderBy(docs, ['docType'], [newDocTypeOrder ? newDocTypeOrder : false])
            setDocTypeOrder(newDocTypeOrder)
            setDocs(sorted)
            setSelectedInvoices([])
            return
        }
    }

    const [fetchDocs, { loading }] = useLazyQuery<Query>(SEARCH_DOCS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: Query) => {
            if (data.searchDocs) {
                //const manyDocs: Doc[] = []
                //for (let i = 0; i < 100; i++) {
                //manyDocs.push(...data.searchDocs)
                //}
                let filtered = data.searchDocs
                if (evnList && evnList.length > 0) {
                    filtered = _.filter(filtered, (v) => {
                        if (v.docType === 'CALL_DETAIL_RECORD') {
                            for (let i = 0; i < evnList.length; i++) {
                                //show only call detail records if 31 or 32
                                if (evnList[i].contractID === v.contractID) {
                                    if (evnList[i].value === '30') {
                                        return false
                                    }
                                    if (evnList[i].value === '31') {
                                        return true
                                    }
                                    if (evnList[i].value === '32') {
                                        return true
                                    }
                                }
                            }
                            return false
                        } else {
                            return true
                        }
                    })
                }
                const sorted = _.orderBy(filtered, ['invoiceTarget'], ['desc'])
                setDocs(sorted)
            }
        },
        onError: (error: ApolloError) => console.log(error),
    })

    const [billAmount, setBillAmount] = useState<SumBill[]>([])
    const [fetchBillAmount, { loading: billIsLoading }] = useLazyQuery<Query>(SUM_BILLS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: Query) => {
            if (data.getSumForBills) {
                //merge old data with old this is a costly operation
                setBillAmount([...billAmount, ...data.getSumForBills])
            }
        },
        onError: (error: ApolloError) => console.log(error),
    })

    // download doc index will be passed here f.e. [0,1,2,3]
    const downloadItems = (vi: number[]) => {
        for (let i = 0; i < vi.length; i++) {
            const offset = (page - 1) * pageSize
            dlDoc(docs[vi[i] + offset].path, docs[vi[i] + offset].bucket, token, docs[vi[i] + offset].downloadName)
        }
        setSelectedInvoices([])
    }

    useEffect(() => {
        //NOTE: the document search service only has one customer right now
        const vars: QuerySearchDocsArgs = {
            docType: ['INVOICE', 'CALL_DETAIL_RECORD'],
        }
        fetchDocs({ variables: vars })
    }, [])

    useEffect(() => {
        if (docs.length > 0) {
            const periodIDs: string[] = []
            const offset = (page - 1) * pageSize
            for (let i = 0 + offset; i < docs.length; i++) {
                //load data for the visible only
                if (i == pageSize - 1 + offset) {
                    break
                }
                if (alreadySearched.includes(docs[i].targetPeriodId)) {
                    continue
                } else {
                    periodIDs.push(docs[i].targetPeriodId)
                }
            }
            // load billing data for the first ten items in rows
            const uniqPeriodIds = _.uniq(periodIDs)
            if (uniqPeriodIds.length === 0) {
                return
            }
            const newAlreadySearched = [...alreadySearched]
            newAlreadySearched.push(...uniqPeriodIds)
            setAlreadySearched(_.uniq(newAlreadySearched))

            const vars: QueryGetSumForBillsArgs = {
                periodIDs: uniqPeriodIds,
            }
            fetchBillAmount({ variables: vars })
        }
    }, [docs, page])

    return {
        invoices: docs,
        pageSize,
        selectedInvoices,
        setSelectedInvoices,
        loading,
        searchTerms,
        downloadItems,
        setInvoices: setDocs,
        items,
        changeOrder,
        selectOne,
        selectAll,
        docTypeOrder,
        dateOrder,
        page,
        billAmount,
        billIsLoading,
        customize,
    }
}

export default useMyInvoicesState
