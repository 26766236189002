import { Customize } from 'utils/customize/customize'
import { useLazyQuery } from '@apollo/client'
import { SearchOption } from 'components/SearchBar/SearchBar'
import { parse } from 'date-fns'
import GetOrderStatus from 'graphql/queries/GetOrderStatus'
import { FaqCategory, OrderStatus, OrderStatusInput, Query, TipsTricks, User } from 'graphql/types'
import _ from 'lodash'
import { Dispatch, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { AppState } from 'store/store'
import { FAQS_TIPS, translateSearchTerms } from 'utils/utils'

interface UseDashboardStateReturn {
    orderStatus: OrderStatus[]
    //invoices: Invoice[]
    user: User | undefined
    searchTerms: SearchOption[]
    customize: Customize | undefined
}

const useDashboardState = (): UseDashboardStateReturn => {
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()
    // const history = useHistory()

    const { t } = useTranslation()
    const [orderStatus, setOrderStatus] = useState<OrderStatus[]>([])

    const { user, searchTerms, customize } = useSelector((appState: AppState) => ({
        user: appState.generalState.user,
        searchTerms: appState.generalState.searchTerms,
        customize: appState.generalState.customize,
    }))

    const setFaqList = useCallback(
        (payload: FaqCategory[]) => {
            dispatch({
                type: GeneralStateAction.SET_FAQ_LIST,
                payload,
            })
        },
        [dispatch],
    )

    const setTipsList = useCallback(
        (payload: TipsTricks[]) => {
            dispatch({
                type: GeneralStateAction.SET_TIPS_LIST,
                payload,
            })
        },
        [dispatch],
    )

    //const setInvoices = useCallback(
    //(payload: Invoice[]) => {
    //dispatch({
    //type: GeneralStateAction.SET_INVOICES,
    //payload,
    //})
    //},
    //[dispatch],
    //)

    const setSearchTerms = useCallback(
        (payload: SearchOption[]) => {
            dispatch({
                type: GeneralStateAction.SET_SEARCH_TERMS,
                payload,
            })
        },
        [dispatch],
    )

    const [getOrderStatus] = useLazyQuery<Query>(GetOrderStatus, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data && data.getOrderStatus) {
                setOrderStatus(data.getOrderStatus)
            }
        },
    })

    useLayoutEffect(() => {
        // @ts-expect-error: Importing faqs_tips from public folder
        const faqs_tips: FAQS_TIPS = window.faqs_tips
        // @ts-expect-error: Importing searchTerms from public folder
        const searchTerms: SearchOption[] = window.searchTerms.options

        setSearchTerms(translateSearchTerms(searchTerms, t))
        setFaqList(faqs_tips.faqs)
        setTipsList(faqs_tips.tips_and_tricks)
    }, [])

    useEffect(() => {
        if (user) {
            if (user.contractMetaData.activeContracts.length > 0) {
                //get newest contract
                const contracts = user.contractMetaData.activeContracts
                const sortedContracts = _.sortBy(contracts, (c) => {
                    return parse(c.contractStart, 'yyyy-MM-dd', new Date()).getTime()
                })
                //search booking status
                const vars: OrderStatusInput = {
                    contractID: sortedContracts[sortedContracts.length - 1].contractID,
                }
                getOrderStatus({ variables: { input: vars } })
            }
        }
    }, [user])

    return {
        orderStatus,
        user,
        searchTerms,
        customize,
    }
}

export default useDashboardState
