import { Box, Grid, Link, Typography } from '@material-ui/core'
import * as React from 'react'
import TestIDs from 'utils/TestIDs'
import { TipsTricks } from '../../graphql/types'

export interface TipsTricksListProps {
    headline: string
    tipsTricksList: TipsTricks[]
}

const TipsTricksList = (props: TipsTricksListProps): JSX.Element => {
    const { tipsTricksList, headline } = props

    return (
        <>
            {headline.length > 0 && (
                <Box width={1}>
                    <Typography variant={'h1'} {...TestIDs.GET_TIPS_TRICKS_LIST('TITLE')}>
                        {headline}
                    </Typography>
                </Box>
            )}
            <Box width={1} marginTop={{ xs: 5, sm: 10 }} marginBottom={{ xs: 5, sm: 8 }}>
                <Box width={1} display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }} marginTop={2}>
                    <Grid container spacing={2}>
                        {tipsTricksList.map((tip: TipsTricks) => (
                            <Grid item xs={12} sm={6} key={tip.id}>
                                <Link
                                    href={`#/tips/${tip.id}`}
                                    underline="always"
                                    color="textPrimary"
                                    variant="body1"
                                    {...TestIDs.GET_TIPS_TRICKS_LIST('TIPS_ENTRY', tip.id)}
                                >
                                    {tip.title}
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default TipsTricksList
