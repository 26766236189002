import ContractDetails from 'components/ContractDetails'
import LoadingSpinner from 'components/LoadingSpinner'
import ViewWrapper from 'components/ViewWrapper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getHeader, navigationLinks } from 'utils/utils'
import { useContractOverview } from './useContractOverview'

export type ContractDocumentOrder = 'date' | 'name'

const ContractOverview = (): JSX.Element => {
    const { t } = useTranslation()
    const history = useHistory()
    const { searchTerms, userID, overview, gotoDocs, loading, activeContractIDs, handleChangeContractView, customize } =
        useContractOverview()

    return (
        <ViewWrapper
            header={getHeader(`/${t('url.myContracts')}`, history, searchTerms, customize)}
            navigation={navigationLinks('logged-in', 'contracts')}
        >
            <div style={{ paddingBottom: 150 }}>
                {overview && (
                    <ContractDetails
                        activeContractIDs={activeContractIDs}
                        customerID={userID}
                        overview={overview}
                        gotoDocs={gotoDocs}
                        handleChangeContractOverview={handleChangeContractView}
                    />
                )}
                {loading && <LoadingSpinner loading={loading} />}
            </div>
        </ViewWrapper>
    )
}

export default ContractOverview
