import gql from 'graphql-tag'

export const GET_PHONEBLOCKED_DATA = gql`
    query GetPhoneBlockedData($phoneNumber: String!) {
        getPhoneBlockedData(phoneNumber: $phoneNumber) {
            number
            isDisabled
            isBlocked
            description
            locks
        }
    }
`
