import { Box, Button, Divider, Grid, Typography } from '@material-ui/core'
import { ContractDocumentTableProps } from 'components/ContractDocumentTable/ContractDocumentTable'
import CustomPagination from 'components/CustomPagination/CustomPagination'
import Dropdown from 'components/Dropdown'
import { format } from 'date-fns'
import { GetApp } from '@material-ui/icons'
import _ from 'lodash'
import { ContractDocumentOrder } from 'pages/ContractOverview/ContractOverview'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import CheckboxComponent from 'components/CheckboxComponent'

const ContractDocumentTableMobile = (props: ContractDocumentTableProps): JSX.Element => {
    const { rows, changeOrder, page, perPage, items, selectedDocs, selectOne, downloadItems } = props
    const [currentOrder, setCurrentOrder] = useState<ContractDocumentOrder>('date')

    const { t } = useTranslation()
    const history = useHistory()
    //const itemMeta = getCurrentItems(page, perPage, rows.length)
    return (
        <>
            <Box width={1} marginBottom={9}>
                <Box>
                    <Typography variant="h1">{t('pages.myContracts.title')}</Typography>
                    <Box marginTop={1}>
                        <Typography variant="body1">{t('pages.myContracts.subtitle')}</Typography>
                    </Box>
                </Box>
            </Box>

            <Grid container>
                <Grid item xs={6}>
                    <Dropdown
                        handleChange={(e) => {
                            changeOrder(e.target.value as ContractDocumentOrder)
                            setCurrentOrder(e.target.value as ContractDocumentOrder)
                        }}
                        label={t('formLabels.sortBy')}
                        options={[
                            { label: t('formLabels.sortTypes.date'), value: 'date' },
                            { label: t('formLabels.sortTypes.typ'), value: 'name' },
                            { label: t('formLabels.sortTypes.displayName'), value: 'displayName' },
                        ]}
                        selectedOption={currentOrder}
                        small
                    />
                </Grid>

                <Grid item xs={6}>
                    <Box margin="7px">
                        <Button fullWidth variant="contained" onClick={() => changeOrder(currentOrder)}>
                            {t('formLabels.sortTypes.invert')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Box height={1}>
                <Divider />
            </Box>
            <Box
                width={1}
                flex={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
            >
                {_.slice(rows, (page - 1) * perPage, page * perPage).map((row, i) => (
                    <React.Fragment key={i}>
                        <Divider />
                        <Box
                            marginTop={2}
                            marginBottom={1.5}
                            width={1}
                            display={'flex'}
                            flexDirection={'column'}
                            onClick={() => selectOne(i)}
                        >
                            <Box width={1} display={'flex'} justifyContent={'space-between'}>
                                <Box paddingRight={0.25} display={'flex'} alignItems={'center'}>
                                    <Typography variant="body1">
                                        {row.invoiceTarget.trim() !== ''
                                            ? format(new Date(row.invoiceTarget), 'dd.MM.yy')
                                            : ''}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant={'subtitle1'} noWrap style={{ fontWeight: 'inherit' }}>
                                        {t('docType.' + row.docType)}{' '}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box marginTop={1} width={1} display={'flex'} justifyContent={'space-between'}>
                                <Box display={'flex'} alignItems={'center'} marginRight={2}>
                                    <CheckboxComponent checked={selectedDocs.includes(i)} label={''} />
                                    <Typography variant={'caption'} style={{ wordBreak: 'break-word' }}>
                                        {row.displayName}
                                    </Typography>
                                </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Button
                                        variant={'outlined'}
                                        className={'Small'}
                                        color={'secondary'}
                                        startIcon={<GetApp style={{ width: 20, height: 20 }} />}
                                        style={{ borderWidth: '2px' }}
                                        onClick={() => downloadItems([i])}
                                    >
                                        PDF
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </React.Fragment>
                ))}
                <Grid container justify="center" alignItems="center" spacing={3}>
                    <Grid item xs={12}>
                        <Button
                            variant={'contained'}
                            fullWidth
                            color={'primary'}
                            disabled={selectedDocs.length === 0}
                            style={{ marginTop: '16px', marginBottom: '8px' }}
                            onClick={() => downloadItems(selectedDocs)}
                        >
                            {t('pages.myInvoices.invoiceList.download')}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant={'outlined'} color={'default'} fullWidth onClick={() => history.goBack()}>
                            {t('general.back')}
                        </Button>
                    </Grid>
                </Grid>
                <CustomPagination items={items} isMobile={true} />
            </Box>
        </>
    )
}

export default ContractDocumentTableMobile
