import { Box, Button, Typography } from '@material-ui/core'
import { ChevronLeft, ChevronRight, FiberManualRecord } from '@material-ui/icons'
import { UsePaginationItem } from '@material-ui/lab'
import React from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'

export interface CustomPaginationProps {
    items: UsePaginationItem[]
    isMobile: boolean
}

const CustomPagination = (props: CustomPaginationProps): JSX.Element => {
    const { items, isMobile } = props
    const { t } = useTranslation()

    const renderPaginationItem = (item: UsePaginationItem, index: number): JSX.Element | undefined => {
        const { type, selected, disabled, ...rest } = item
        if (!isMobile && (type === 'start-ellipsis' || type === 'end-ellipsis')) {
            return (
                <Box display={'flex'} flex={1} justifyContent={'center'} key={item.page + type + index}>
                    <Typography key={index} style={{ color: colors.darkIcon, marginBottom: 7 }}>
                        &hellip;
                    </Typography>
                </Box>
            )
        }
        if (type === 'previous') {
            return (
                <Box display={'flex'} flex={1} justifyContent={'flex-start'} key={item.page + type + index}>
                    <Button disabled={disabled} key={index} color={'primary'} {...rest}>
                        <ChevronLeft style={{ color: disabled ? colors.darkIcon : colors.main }} />
                        <Typography
                            style={{ color: disabled ? colors.darkIcon : colors.main, textTransform: 'uppercase' }}
                        >
                            {t('pages.myInvoices.invoiceList.previous')}
                        </Typography>
                    </Button>
                </Box>
            )
        }
        if (type === 'next') {
            return (
                <Box display={'flex'} flex={1} justifyContent={'flex-end'} key={item.page + type + index}>
                    <Button disabled={disabled} key={index} color={'primary'} {...rest}>
                        <Typography
                            style={{ color: disabled ? colors.darkIcon : colors.main, textTransform: 'uppercase' }}
                        >
                            {t('pages.myInvoices.invoiceList.next')}
                        </Typography>
                        <ChevronRight style={{ color: disabled ? colors.darkIcon : colors.main }} />
                    </Button>
                </Box>
            )
        }
        if (!isMobile) {
            return (
                <Box display={'flex'} flex={1} justifyContent={'center'} key={item.page + type + index}>
                    <Button disabled={disabled} key={index} color={'primary'} {...rest}>
                        <FiberManualRecord
                            style={{ color: !selected ? `${colors.darkIcon}3D` : colors.main, width: 8, height: 8 }}
                        />
                    </Button>
                </Box>
            )
        }
    }
    return (
        <Box width={1} flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            {items.map(renderPaginationItem)}
        </Box>
    )
}

export default CustomPagination
