import { Box, CircularProgress, Grid } from '@material-ui/core'
import { AuthClientError, AuthClientEvent } from '@react-keycloak/core'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from 'store/store'
import keycloak from 'utils/keycloak'
import App from './App'
import './index.css'

const eventLogger = (event: AuthClientEvent, error?: AuthClientError): void => {
    console.log('onKeycloakEvent', event, error)
}

ReactDOM.render(
    <Suspense
        fallback={
            <Grid container item xs={12}>
                <Box
                    height={1}
                    width={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignContent={'center'}
                    marginTop={'calc(100vh / 2)'}
                >
                    <CircularProgress size={80} />
                </Box>
            </Grid>
        }
    >
        <React.StrictMode>
            <ReactKeycloakProvider authClient={keycloak()} onEvent={eventLogger}>
                <Provider store={store}>
                    <App />
                </Provider>
            </ReactKeycloakProvider>
        </React.StrictMode>
    </Suspense>,
    document.getElementById('root'),
)
