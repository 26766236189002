export enum Routes {
    PhoneNumberBlockEdit = '/phoneNumberBlockEdit',
    PhoneBookEntryEdit = '/phonebookEntryEdit',
    PhoneNumberDisplayEdit = '/phoneNumberDisplayEdit',
    ReactivateRegistration = '/reactivateRegistration',
    ValidateRegistration = '/validateRegistration/:id/:key',
    ForgotUsername = '/forgotUsername',
    ChangeInvoice = '/changeInvoice',
    NewSepa = '/newSEPA',
    CancelContract = '/cancelContract/:contractID?',
    CancelContractConfirmation = '/cancelContractConfirmation/:contractID/:productName/:date/:targetEnd/:kind/:reason',
    MyData = '/myData',
    Invoices = '/myInvoices',
    Tips = '/tips',
    TipsID = '/tips/:tipID?',
    PersonalDataEdit = '/personalDataEdit',
    BankDataEdit = '/bankDataEdit',
    OtherAddrEdit = '/otherAddrEdit',
    ContractDocs = '/contractDocs',
    ContractOverview = '/contractOverview',
    Help = '/help',
    HelpDetails = '/help/:displayMethod/:categoryID/:questionID?',
    Login = '/login',
    LoginEdit = '/loginEditPage',
    Register = '/register',
    Dashboard = '/dashBoard',
}
