import { useMutation } from '@apollo/client'
import { Box, Button, Fade, Grid, TextField, Typography, useTheme } from '@material-ui/core'
import DialogComponent from 'components/DialogComponent/DialogComponent'
import Dropdown from 'components/Dropdown'
import ErrorBox from 'components/ErrorBox'
import FieldWrapper from 'components/FieldWrapper'
import ViewWrapper from 'components/ViewWrapper'
import { DeleteCustomerAddress } from 'graphql/mutations/DeleteCustomerAddr'
import { UpdateCustomerAddress } from 'graphql/mutations/UpdateCustomerAddress'
import { Address, Mutation, MutationDeleteCustomerAddressArgs, MutationUpdateCustomerAddressArgs } from 'graphql/types'
import useEditState from 'hooks/useEditState'
import { dataHasErrors, editField, isAddressEmpty, isNewAddress } from 'hooks/useEditState/EditStateFuncs'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { MutableRefObject, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'theme/colors'
import { Routes } from 'routes/routes'
import TestIDs from 'utils/TestIDs'
import DeleteIcon from '@material-ui/icons/Delete'
import {
    addressIsFilled,
    dropdownOptionsCountry,
    dropdownOptionsSalutation,
    getHeader,
    navigationLinks,
} from 'utils/utils'

const OtherAddrEdit = (): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar()
    const {
        user,
        userCopy,
        history,
        searchTerms,
        saveUserData,
        validation,
        setUserCopyPartial,
        touched,
        setUser,
        savePressed,
        setSavePressed,
        customize,
    } = useEditState(editField.otherAddrData)

    const postErrors: MutableRefObject<string[]> = useRef<string[]>([])
    const { t } = useTranslation()
    const theme = useTheme()

    if (!userCopy || Object.keys(userCopy ?? {}).length === 0) {
        history.push('/')
        return <></>
    }

    const [postAddress, { loading }] = useMutation<Mutation>(UpdateCustomerAddress, {
        onCompleted: (data) => {
            if (data.updateCustomerAddress.Success === true) {
                // there will be a save notification if it succeeds and is updating the state
                //enqueueSnackbar(t('notification.saveSuccess') + data.updateCustomerAddress.AddressKind, {
                //variant: 'success',
                //})
            } else {
                postErrors.current.push(data.updateCustomerAddress.AddressKind)
                enqueueSnackbar(t('notification.saveFailed') + data.updateCustomerAddress.AddressKind, {
                    variant: 'error',
                })
            }
        },
    })
    const [deleteAddress, { loading: delLoading }] = useMutation<Mutation>(DeleteCustomerAddress, {
        onCompleted: (data) => {
            if (data.deleteCustomerAddress === true) {
                enqueueSnackbar(t('notification.deleteSuccess'), {
                    variant: 'success',
                })
                const emptyAddr: Address = {
                    companyLegalForm: '',
                    companyName: '',
                    salutation: '',
                    title: '',
                    firstName: '',
                    lastName: '',
                    street: '',
                    zipCode: '',
                    city: '',
                    country: '',
                    houseNumber: '',
                    houseNumberExtension: '',
                    __typename: 'Address',
                }
                // updateFrontend
                if (deleteAddrKind === 'DELIVERY') {
                    setUserCopyPartial({
                        deliveryAddress: emptyAddr,
                    })
                    setUser({ ...user, deliveryAddress: emptyAddr })
                }

                if (deleteAddrKind === 'BILLING') {
                    setUserCopyPartial({
                        billingAddress: emptyAddr,
                    })
                    setUser({ ...user, billingAddress: emptyAddr })
                }
            } else {
                enqueueSnackbar(t('notification.saveFailed'), {
                    variant: 'error',
                })
            }
        },
    })
    const handleSaveDelivery = async (): Promise<void> => {
        if (dataHasErrors(userCopy, editField.otherAddrData).errors.length === 0) {
            const deliveryFilled = addressIsFilled(userCopy.deliveryAddress)

            //NOTE: this is for the user to avoid deleteing each field and revert it
            // deleting is only possible with the delete button
            if (deliveryFilled === false) {
                setUserCopyPartial({ deliveryAddress: user.deliveryAddress })
            }

            if (!_.isEqual(user.deliveryAddress, userCopy.deliveryAddress) && deliveryFilled) {
                const vars: MutationUpdateCustomerAddressArgs = {
                    input: {
                        companyLegalForm: userCopy.deliveryAddress.companyLegalForm.trim(),
                        companyName: userCopy.deliveryAddress.companyName.trim(),
                        salutation: userCopy.deliveryAddress.salutation.trim(),
                        title: userCopy.deliveryAddress.title.trim(),
                        firstName: userCopy.deliveryAddress.firstName.trim(),
                        lastName: userCopy.deliveryAddress.lastName.trim(),
                        street: userCopy.deliveryAddress.street.trim(),
                        houseNumber: userCopy.deliveryAddress.houseNumber.trim(),
                        houseNumberExtension: userCopy.deliveryAddress.houseNumberExtension.trim(),
                        zipCode: userCopy.deliveryAddress.zipCode.trim(),
                        city: userCopy.deliveryAddress.city.trim(),
                        country: userCopy.deliveryAddress.country.trim(),
                    },
                    kind: 'DELIVERY',
                }
                await postAddress({ variables: vars })
                if (postErrors.current.length > 0) {
                    postErrors.current = []
                    //NOTE: error has been reported in the callback of postAddress
                    return
                }
                saveUserData({ ...user, deliveryAddress: userCopy.deliveryAddress })
            }
        } else {
            if (!savePressed) {
                setSavePressed(true)
            }
        }
    }

    const handleSaveBilling = async (): Promise<void> => {
        if (dataHasErrors(userCopy, editField.otherAddrData).errors.length === 0) {
            const billingFilled = addressIsFilled(userCopy.billingAddress)

            //NOTE: this is for the user to avoid deleteing each field and revert it
            // deleting is only possible with the delete button
            if (billingFilled === false) {
                setUserCopyPartial({ billingAddress: user.billingAddress })
            }

            if (!_.isEqual(user.billingAddress, userCopy.billingAddress) && billingFilled) {
                const vars: MutationUpdateCustomerAddressArgs = {
                    input: {
                        companyName: userCopy.billingAddress.companyName.trim(),
                        companyLegalForm: userCopy.billingAddress.companyLegalForm.trim(),
                        salutation: userCopy.billingAddress.salutation.trim(),
                        title: userCopy.billingAddress.title.trim(),
                        firstName: userCopy.billingAddress.firstName.trim(),
                        lastName: userCopy.billingAddress.lastName.trim(),
                        street: userCopy.billingAddress.street.trim(),
                        houseNumber: userCopy.billingAddress.houseNumber.trim(),
                        houseNumberExtension: userCopy.billingAddress.houseNumberExtension.trim(),
                        zipCode: userCopy.billingAddress.zipCode.trim(),
                        city: userCopy.billingAddress.city.trim(),
                        country: userCopy.billingAddress.country.trim(),
                    },
                    kind: 'BILLING',
                }
                await postAddress({ variables: vars })
                if (postErrors.current.length > 0) {
                    postErrors.current = []
                    //NOTE: error has been reported in the callback of postAddress
                    return
                }
                saveUserData({ ...user, billingAddress: userCopy.billingAddress })
            }
        } else {
            if (!savePressed) {
                setSavePressed(true)
            }
        }
    }

    const handleCancel = (): void => {
        history.goBack()
    }

    const handleDeliveryPartial = (val: Partial<Address>) => {
        setUserCopyPartial({
            deliveryAddress: { ...userCopy.deliveryAddress, ...val },
        })
    }
    const handleBillingPartial = (val: Partial<Address>) => {
        setUserCopyPartial({
            billingAddress: { ...userCopy.billingAddress, ...val },
        })
    }

    const [deleteAddrKind, setDeleteAddrKind] = useState<string>('')
    const handleDeleteAddrModal = (kind: string) => {
        if (kind === '' || kind === 'DEFAULT') {
            console.log('empty or default not allowed')
            return
        }
        setOpen(true)
        setDeleteAddrKind(kind)
    }

    const deleteAddr = async () => {
        const vars: MutationDeleteCustomerAddressArgs = {
            kind: deleteAddrKind,
        }
        deleteAddress({ variables: vars })
        setOpen(false)
    }

    const [open, setOpen] = useState(false)

    const deliveryError = useMemo(() => {
        return { errors: validation.errors.filter((e) => e.includes('-delivery')), errorsTitle: undefined }
    }, [validation])

    const billingError = useMemo(() => {
        return { errors: validation.errors.filter((e) => e.includes('-billing')), errorsTitle: undefined }
    }, [validation])

    return (
        <ViewWrapper
            header={getHeader(Routes.Dashboard, history, searchTerms, customize)}
            navigation={navigationLinks('logged-in', 'myData')}
        >
            <>
                <Box marginBottom={5}>
                    <Typography variant={'h2'}>{t('pages.myData.edit.DeliveryAddr')}</Typography>
                </Box>

                <FieldWrapper text={''}>
                    <Grid container item spacing={2} style={{ backgroundColor: theme.palette.primary.light }}>
                        <Grid item xs={12} md={6}>
                            <Dropdown
                                id={'salutation'}
                                disabled={false}
                                focused={undefined}
                                label={'* ' + t('formLabels.salutation')}
                                options={dropdownOptionsSalutation(t)}
                                selectedOption={userCopy.deliveryAddress.salutation}
                                handleChange={(e) => {
                                    handleDeliveryPartial({ salutation: e.target.value as string })
                                }}
                                testIdentifier={TestIDs.GET_OTHER_ADDR_EDIT('D-SALUTATION')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.title')}
                                variant={'filled'}
                                value={userCopy.deliveryAddress.title}
                                inputProps={{
                                    maxLength: 30,
                                }}
                                onChange={(e) => {
                                    handleDeliveryPartial({ title: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('D-TITLE')}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.company')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={userCopy.deliveryAddress.companyName}
                                onChange={(e) => {
                                    handleDeliveryPartial({ companyName: e.target.value })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.legalForm')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 30,
                                }}
                                value={userCopy.deliveryAddress.companyLegalForm}
                                onChange={(e) => {
                                    handleDeliveryPartial({ companyLegalForm: e.target.value })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.firstName')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={userCopy.deliveryAddress.firstName}
                                onChange={(e) => {
                                    handleDeliveryPartial({ firstName: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('D-FNAME')}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.lastName')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={userCopy.deliveryAddress.lastName}
                                onChange={(e) => {
                                    handleDeliveryPartial({ lastName: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('D-LNAME')}
                            />
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.street')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={userCopy.deliveryAddress.street}
                                onChange={(e) => {
                                    handleDeliveryPartial({ street: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('D-STREET')}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.houseNumber')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 10,
                                }}
                                value={userCopy.deliveryAddress.houseNumber}
                                onChange={(e) => {
                                    handleDeliveryPartial({ houseNumber: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('D-HOUSE_NUMBER')}
                            />
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.houseNumberExtension_short')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 5,
                                }}
                                value={userCopy.deliveryAddress.houseNumberExtension}
                                onChange={(e) => {
                                    handleDeliveryPartial({ houseNumberExtension: e.target.value })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.zip')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 5,
                                }}
                                value={userCopy.deliveryAddress.zipCode}
                                onChange={(e) => {
                                    if (new RegExp('^[0-9]*$').test(e.target.value)) {
                                        handleDeliveryPartial({ zipCode: e.target.value })
                                    }
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('D-ZIP')}
                            />
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.city')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={userCopy.deliveryAddress.city}
                                onChange={(e) => {
                                    handleDeliveryPartial({ city: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('D-CITY')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Dropdown
                                id={'country'}
                                disabled={false}
                                focused={undefined}
                                label={'* ' + t('formLabels.country')}
                                options={dropdownOptionsCountry(t)}
                                selectedOption={userCopy.deliveryAddress.country}
                                handleChange={(e) => {
                                    handleDeliveryPartial({ country: e.target.value as string })
                                }}
                                testIdentifier={TestIDs.GET_OTHER_ADDR_EDIT('D-COUNTRY')}
                            />
                        </Grid>

                        <Fade in={deliveryError.errors.length > 0} unmountOnExit={true}>
                            <Grid item xs={12}>
                                <Box>
                                    <ErrorBox {...deliveryError} />
                                </Box>
                            </Grid>
                        </Fade>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant={'contained'}
                                color={'default'}
                                fullWidth
                                disabled={
                                    isAddressEmpty(userCopy.deliveryAddress) ||
                                    isNewAddress(userCopy.deliveryAddress, user.deliveryAddress) ||
                                    delLoading
                                }
                                onClick={() => handleDeleteAddrModal('DELIVERY')}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('D-DELETE_BUTTON')}
                            >
                                {t('deliveryAddress') + ' ' + t('general.delete')}
                                <DeleteIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                fullWidth
                                onClick={handleSaveDelivery}
                                disabled={loading || !touched || validation.errors.length > 0}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('SUBMIT_BUTTON')}
                            >
                                {t('deliveryAddress') + ' ' + t('general.save')}
                                {loading ? '...' : ''}
                            </Button>
                        </Grid>
                    </Grid>
                </FieldWrapper>

                <Box
                    marginTop={5}
                    marginBottom={5}
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                ></Box>

                <Box marginBottom={5} marginTop={5}>
                    <Typography variant={'h2'}>{t('pages.myData.edit.BillingAddr')}</Typography>
                </Box>

                <FieldWrapper text={''}>
                    <Grid container item spacing={2} style={{ backgroundColor: theme.palette.primary.light }}>
                        <Grid item xs={12} md={6}>
                            <Dropdown
                                id={'salutation'}
                                disabled={false}
                                focused={undefined}
                                label={'* ' + t('formLabels.salutation')}
                                options={dropdownOptionsSalutation(t)}
                                selectedOption={userCopy.billingAddress.salutation}
                                handleChange={(e) => {
                                    handleBillingPartial({ salutation: e.target.value as string })
                                }}
                                testIdentifier={TestIDs.GET_OTHER_ADDR_EDIT('B-SALUTATION')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.title')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 30,
                                }}
                                value={userCopy.billingAddress.title}
                                onChange={(e) => {
                                    handleBillingPartial({ title: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('B-TITLE')}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.company')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={userCopy.billingAddress.companyName}
                                onChange={(e) => {
                                    handleBillingPartial({ companyName: e.target.value })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.legalForm')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 30,
                                }}
                                value={userCopy.billingAddress.companyLegalForm}
                                onChange={(e) => {
                                    handleBillingPartial({ companyLegalForm: e.target.value })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.firstName')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={userCopy.billingAddress.firstName}
                                onChange={(e) => {
                                    handleBillingPartial({ firstName: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('B-FNAME')}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.lastName')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={userCopy.billingAddress.lastName}
                                onChange={(e) => {
                                    handleBillingPartial({ lastName: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('B-LNAME')}
                            />
                        </Grid>

                        <Grid item xs={12} md={7}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.street')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={userCopy.billingAddress.street}
                                onChange={(e) => {
                                    handleBillingPartial({ street: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('B-STREET')}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.houseNumber')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 10,
                                }}
                                value={userCopy.billingAddress.houseNumber}
                                onChange={(e) => {
                                    handleBillingPartial({ houseNumber: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('B-HOUSE_NUMBER')}
                            />
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <TextField
                                fullWidth={true}
                                label={t('formLabels.houseNumberExtension_short')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 5,
                                }}
                                value={userCopy.billingAddress.houseNumberExtension}
                                onChange={(e) => {
                                    handleBillingPartial({ houseNumberExtension: e.target.value })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.zip')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 5,
                                }}
                                value={userCopy.billingAddress.zipCode}
                                onChange={(e) => {
                                    if (new RegExp('^[0-9]*$').test(e.target.value)) {
                                        handleBillingPartial({ zipCode: e.target.value })
                                    }
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('B-ZIP')}
                            />
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <TextField
                                fullWidth={true}
                                label={'* ' + t('formLabels.city')}
                                variant={'filled'}
                                inputProps={{
                                    maxLength: 50,
                                }}
                                value={userCopy.billingAddress.city}
                                onChange={(e) => {
                                    handleBillingPartial({ city: e.target.value })
                                }}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('B-CITY')}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Dropdown
                                id={'country'}
                                disabled={false}
                                focused={undefined}
                                label={'* ' + t('formLabels.country')}
                                options={dropdownOptionsCountry(t)}
                                selectedOption={userCopy.billingAddress.country}
                                handleChange={(e) => {
                                    handleBillingPartial({ country: e.target.value as string })
                                }}
                                testIdentifier={TestIDs.GET_OTHER_ADDR_EDIT('B-COUNTRY')}
                            />
                        </Grid>

                        <Fade in={billingError.errors.length > 0} unmountOnExit={true}>
                            <Grid item xs={12}>
                                <Box>
                                    <ErrorBox {...billingError} />
                                </Box>
                            </Grid>
                        </Fade>

                        <Grid item xs={12} sm={6}>
                            <Button
                                variant={'contained'}
                                color={'default'}
                                fullWidth
                                disabled={
                                    isAddressEmpty(userCopy.billingAddress) ||
                                    isNewAddress(userCopy.billingAddress, user.billingAddress) ||
                                    delLoading
                                }
                                onClick={() => handleDeleteAddrModal('BILLING')}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('B-DELETE_BUTTON')}
                            >
                                {t('billingAddress') + ' ' + t('general.delete')}
                                <DeleteIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                fullWidth
                                onClick={handleSaveBilling}
                                disabled={loading || !touched || validation.errors.length > 0}
                                {...TestIDs.GET_OTHER_ADDR_EDIT('SAVE-BILLING')}
                            >
                                {t('billingAddress') + ' ' + t('general.save')}
                                {loading ? '...' : ''}
                            </Button>
                        </Grid>
                    </Grid>
                </FieldWrapper>

                <Box
                    marginTop={5}
                    marginBottom={5}
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                >
                    <Button
                        variant={'outlined'}
                        color={'default'}
                        onClick={handleCancel}
                        {...TestIDs.GET_OTHER_ADDR_EDIT('CANCEL_BUTTON')}
                    >
                        {touched ? t('general.cancel') : t('general.back')}
                    </Button>
                </Box>
                <DialogComponent
                    open={open}
                    onAccept={deleteAddr}
                    onCancel={() => setOpen(false)}
                    title={t('deleteAddr.header')}
                    content={
                        <Box pb={2}>
                            <Typography style={{ display: 'inline' }}>{t('deleteAddr.helper') + ' '}</Typography>
                            <Typography style={{ display: 'inline', color: colors.main, fontWeight: 600 }}>
                                {t('deleteAddr.' + deleteAddrKind) + ' '}
                            </Typography>
                            <Typography style={{ display: 'inline' }}>{t('deleteAddr.helper2')}</Typography>
                        </Box>
                    }
                    labelAccept={t('deleteAddr.yes')}
                    labelCancel={t('general.cancel')}
                />
            </>
        </ViewWrapper>
    )
}

export default OtherAddrEdit
