import { Box, Divider, Typography } from '@material-ui/core'
import { FaqCategory } from 'graphql/types'
import * as React from 'react'
import TestIDs from 'utils/TestIDs'

export interface HelpTextProps {
    faqs: FaqCategory
}

const HelpText = (props: HelpTextProps): JSX.Element => {
    const { faqs } = props

    return (
        <Box {...TestIDs.GET_HELP_DETAILS('FAQ_LIST', 'text')}>
            {faqs.questions.map((question) => (
                <Box key={question.id} id={question.id}>
                    <Box>
                        <Typography variant={'h2'} {...TestIDs.GET_HELP_DETAILS('QUESTION', question.id)}>
                            {question.question}
                        </Typography>
                    </Box>
                    <Box marginTop={3}>
                        <Typography
                            dangerouslySetInnerHTML={{ __html: question.answer }}
                            {...TestIDs.GET_HELP_DETAILS('ANSWER', question.id)}
                        />
                    </Box>
                    <Box marginTop={4} marginBottom={4}>
                        <Divider />
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

export default HelpText
