import { useMutation } from '@apollo/client'
import { Box, Typography, Grid, TextField, makeStyles } from '@material-ui/core'
import DashboardSectionBox from 'components/DashboardSectionBox'
import Dropdown from 'components/Dropdown'
import { useViewWrapperData } from 'components/ViewWrapper/useViewWrapperData'
import { SEND_CONTACT_FORM } from 'graphql/mutations/SendContactForm'
import { Mutation, MutationSendContactFormArgs } from 'graphql/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'
import colors from 'theme/colors'
import TestIDs from 'utils/TestIDs'

const useStyles = makeStyles({
    disabledTextField: {
        backgroundColor: colors.lightBorderGray,
        borderRadius: 5,
    },
    TextFieldCustom: {
        backgroundColor: colors.white,
        borderRadius: 5,
    },
})

const ContactForm = (): JSX.Element => {
    const { user } = useSelector((appState: AppState) => ({
        user: appState.generalState.user,
    }))
    const { t } = useTranslation()
    const classes = useStyles()
    const [message, setMessage] = useState<string>('')
    const [selectedOption, setSelectedOption] = useState<string>('0')
    const { loggedUser } = useViewWrapperData()
    const [displayButtonSubtitle, setDisplayButtonSubtitle] = useState(false)

    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setDisplayButtonSubtitle(false)
        setMessage(e.target.value)
    }

    const options = [
        { label: t('pages.helpOverview.contactForm.option0'), value: '0' },
        { label: t('pages.helpOverview.contactForm.option1'), value: '1' },
        { label: t('pages.helpOverview.contactForm.option2'), value: '2' },
        { label: t('pages.helpOverview.contactForm.option3'), value: '3' },
        { label: t('pages.helpOverview.contactForm.option4'), value: '4' },
        { label: t('pages.helpOverview.contactForm.option5'), value: '5' },
        { label: t('pages.helpOverview.contactForm.option6'), value: '6' },
        { label: t('pages.helpOverview.contactForm.option7'), value: '7' },
    ]

    const handleClick = (): void => {
        const vars: MutationSendContactFormArgs = {
            input: {
                message,
                subjectOption: options[Number(selectedOption)].label,
                customerID: loggedUser.username,
                email: loggedUser.email,
                username: user ? `${user.lastName} ${user.firstName}` : '',
            },
        }
        sendContactForm({ variables: vars })
    }
    const [sendContactForm] = useMutation<Mutation>(SEND_CONTACT_FORM, {
        onCompleted: (data) => {
            if (data.sendContactForm) {
                setDisplayButtonSubtitle(true)
            }
        },
    })

    return (
        <Box {...TestIDs.GET_HELP_OVERVIEW('TICKET_BOX')}>
            <DashboardSectionBox
                title={t('pages.helpOverview.contactForm.title')}
                buttonProps2={{
                    label: displayButtonSubtitle
                        ? t('pages.helpOverview.contactForm.btnAlt')
                        : t('pages.helpOverview.contactForm.btn'),
                    onClick: () => {
                        handleClick()
                        setDisplayButtonSubtitle(true)
                    },
                    disabled: message.length === 0,
                }}
                buttonSubtitle={t('pages.helpOverview.contactForm.btnSubtitle')}
                displayButtonSubtitle={displayButtonSubtitle}
            >
                <>
                    <Grid item xs={12}>
                        <Box marginBottom={2}>
                            <Typography
                                dangerouslySetInnerHTML={{
                                    __html: t('pages.helpOverview.contactForm.subtitle', {
                                        username: loggedUser.username,
                                    }),
                                }}
                            ></Typography>
                        </Box>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Dropdown
                                label={t('pages.helpOverview.contactForm.dropDownLabel')}
                                handleChange={(e) => {
                                    setDisplayButtonSubtitle(false)
                                    setSelectedOption(e.target.value as string)
                                }}
                                options={options}
                                selectedOption={selectedOption}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                rows={5}
                                fullWidth={true}
                                label={t('pages.helpOverview.contactForm.textFieldLabel')}
                                variant={'filled'}
                                value={message}
                                onChange={handleMessageChange}
                                inputProps={{
                                    maxLength: 1000,
                                }}
                                InputProps={{
                                    classes: {
                                        input: classes.TextFieldCustom,
                                    },
                                }}
                                {...TestIDs.GET_LOGIN_EDIT('MAIL')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                dangerouslySetInnerHTML={{ __html: t('pages.helpOverview.contactForm.extraInfo') }}
                            />
                            <Typography variant={'caption'}>
                                {t('pages.helpOverview.contactForm.requiredFields')}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            </DashboardSectionBox>
        </Box>
    )
}

export default ContactForm
