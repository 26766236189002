import {
    Box,
    Button,
    Fade,
    FormControl,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core'
import CheckboxComponent from 'components/CheckboxComponent'
import Dropdown from 'components/Dropdown'
import { PhoneNumberContext } from 'components/NumberBlock/NumberBlock'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import colors from 'theme/colors'

export interface PhoneBookEntryProps {
    numbers: PhoneNumberContext[]
    numberOption: PhonebookOptions
    handleNumberOption: (o: Partial<PhonebookOptions>) => void
    selected: number
    handleChangeOption(e: any): void
    handleSave: () => void
    dropDownOpts: any
    saveLoading: boolean
}

export interface PhonebookOptions {
    phoneNumber: string
    electronic: boolean
    infoService: boolean
    inverse: boolean
    printedParticipantDir: boolean
    withAddr: boolean
}

const useStyles = makeStyles(() => ({
    customDrop: {
        maxWidth: '95%',
        '& .MuiFilledInput-root': {
            backgroundColor: colors.white,
            '&.Mui-disabled': {
                backgroundColor: colors.lightGray,
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: colors.white,
            },
        },
    },
}))

const PhoneBookEntry = (props: PhoneBookEntryProps): JSX.Element => {
    const classes = useStyles()
    const {
        numbers,
        numberOption,
        selected,
        handleChangeOption,
        dropDownOpts,
        handleNumberOption,
        handleSave,
        saveLoading,
    } = props

    const [showAddr, setShowAddr] = useState(false)
    const [touched, setTouched] = useState(false)
    const { t } = useTranslation()
    const history = useHistory()

    const oneCheckboxIsTrue = () => {
        if (
            numberOption.printedParticipantDir ||
            numberOption.electronic ||
            numberOption.infoService ||
            numberOption.inverse
        ) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        const oneSelected = oneCheckboxIsTrue()
        if (showAddr === false && oneSelected) {
            setShowAddr(true)
        }
        if (showAddr === true && !oneSelected) {
            setShowAddr(false)
            //setSelectedNumberOptions({ ...selectedNumberOptions, withAddr: false })
        }
    }, [numberOption])

    return (
        <Grid container spacing={6} justify="center" alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h1">{t('phoneBookEntry.title')}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Box className={classes.customDrop}>
                    <Dropdown
                        id={'phoneNumbers'}
                        disabled={numbers.length === 0}
                        focused={undefined}
                        label={t('numberLock.telNumber')}
                        options={numbers.length > 0 ? dropDownOpts : []}
                        selectedOption={numbers.length > 0 ? dropDownOpts[selected].label : ''}
                        handleChange={handleChangeOption}
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: colors.lightGray,
                        width: '100%',
                        paddingTop: 42,
                        paddingBottom: 42,
                        paddingLeft: 36,
                        paddingRight: 36,
                    }}
                >
                    <CheckboxComponent
                        checked={numberOption.printedParticipantDir}
                        label={<Typography variant="body1">{t('phoneBookEntry.inPrintedParticipantDir')}</Typography>}
                        onSelect={() => {
                            if (!touched) {
                                setTouched(true)
                            }
                            handleNumberOption({ printedParticipantDir: !numberOption.printedParticipantDir })
                        }}
                    />
                    <CheckboxComponent
                        checked={numberOption.electronic}
                        label={<Typography variant="body1">{t('phoneBookEntry.inElectronicSubscriberDir')}</Typography>}
                        onSelect={() => {
                            if (!touched) {
                                setTouched(true)
                            }
                            handleNumberOption({ electronic: !numberOption.electronic })
                        }}
                    />
                    <CheckboxComponent
                        checked={numberOption.infoService}
                        label={<Typography variant="body1">{t('phoneBookEntry.inInformationServices')}</Typography>}
                        onSelect={() => {
                            if (!touched) {
                                setTouched(true)
                            }
                            handleNumberOption({ infoService: !numberOption.infoService })
                        }}
                    />
                    <CheckboxComponent
                        checked={numberOption.inverse}
                        label={<Typography variant="body1">{t('phoneBookEntry.allowInverseSearch')}</Typography>}
                        onSelect={() => {
                            if (!touched) {
                                setTouched(true)
                            }
                            handleNumberOption({ inverse: !numberOption.inverse })
                        }}
                    />
                </Box>
            </Grid>
            <Fade in={showAddr} timeout={1000}>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            defaultValue="top"
                            className="phoneBookEntryAddr"
                        >
                            <FormControlLabel
                                value="end"
                                control={
                                    <Radio
                                        checked={numberOption.withAddr}
                                        onClick={() => {
                                            handleNumberOption({ withAddr: true })
                                        }}
                                        color="default"
                                    />
                                }
                                label={t('phoneBookEntry.withAddr')}
                            />
                            <FormControlLabel
                                value="end"
                                label={t('phoneBookEntry.withoutAddr')}
                                control={
                                    <Radio
                                        checked={!numberOption.withAddr}
                                        onClick={() => {
                                            handleNumberOption({ withAddr: false })
                                        }}
                                        color="default"
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Fade>
            <Grid item xs={12}>
                <Box
                    marginTop={5}
                    marginBottom={5}
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                >
                    <Button variant={'outlined'} color={'default'} onClick={() => history.goBack()}>
                        {touched ? t('general.cancel') : t('general.back')}
                    </Button>

                    <Button
                        className={'mt1'}
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleSave}
                        disabled={!touched || saveLoading}
                    >
                        {t('phoneBookEntry.save')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

export default PhoneBookEntry
