import { Box, makeStyles, Typography } from '@material-ui/core'
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from '@material-ui/lab'
import { OrderStatus as OrderStatusType, OrderStatusEnum } from 'graphql/types'
import * as React from 'react'
import colors from 'theme/colors'

export interface OrderStatusProps {
    statuses: OrderStatusType[]
}

const getGradient = (currentStatus: OrderStatusEnum, nextStatus: OrderStatusEnum): string => {
    const firstColor = colorForStatus(currentStatus)
    const secondColor = colorForStatus(nextStatus)
    return `linear-gradient(180deg, ${firstColor} 0%, ${firstColor} 50%, ${secondColor} 50%, ${secondColor} 100%)`
}

const colorForStatus = (s: OrderStatusEnum): string => {
    switch (s) {
        case OrderStatusEnum.DONE:
            return colors.orderStatusCompleted
        case OrderStatusEnum.NEW:
            return colors.orderStatusNotCompleted
        case OrderStatusEnum.IN_PROGRESS:
            return colors.orderStatusInProgress
        case OrderStatusEnum.WARN:
            return colors.orderStatusWarn
        case OrderStatusEnum.ERROR:
            return colors.orderStatusError
        default:
            return colors.lightDarkerGray
    }
}

const useStyles = makeStyles(() => ({
    timeline: { margin: 0, padding: 0, flex: 'unset' },
    timelineItem: {
        minHeight: 0,
        '&:before': {
            content: null,
            flex: 'unset',
            padding: 0,
        },
    },
    timelineDot: { padding: 0, margin: 0, border: 0 },
    timelineConnector: {
        minHeight: 28,
        width: 4,
    },
    statusBox: {
        display: 'flex',
        width: 40,
        height: 40,
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

const OrderStatus = (props: OrderStatusProps): JSX.Element => {
    const { statuses } = props
    const classes = useStyles()

    const CompletedStatus = (): JSX.Element => (
        <Box className={classes.statusBox} bgcolor={colors.orderStatusCompleted}>
            <img src={process.env.PUBLIC_URL + '/customize/icons/check.svg'} alt={''} height={25} width={25} />
        </Box>
    )

    const NotCompletedStatus = (): JSX.Element => (
        <Box className={classes.statusBox} bgcolor={colors.orderStatusNotCompleted}></Box>
    )

    const InProgressStatus = (): JSX.Element => (
        <Box className={classes.statusBox} bgcolor={colors.orderStatusInProgress}>
            <img src={process.env.PUBLIC_URL + '/customize/icons/progress.svg'} alt={''} height={25} width={25} />
        </Box>
    )

    const WarnStatus = (): JSX.Element => (
        <Box className={classes.statusBox} bgcolor={colors.orderStatusWarn}>
            <img src={process.env.PUBLIC_URL + '/customize/icons/mark.svg'} alt={''} height={25} width={25} />
        </Box>
    )
    const ErrorStatus = (): JSX.Element => (
        <Box className={classes.statusBox} bgcolor={colors.orderStatusError}>
            <img src={process.env.PUBLIC_URL + '/customize/icons/error.svg'} alt={''} height={25} width={25} />
        </Box>
    )

    if (statuses.length === 0) {
        return <></>
    }

    return (
        <Timeline align={'left'} className={classes.timeline}>
            {statuses.map((item, index) => {
                return (
                    <TimelineItem key={index} className={classes.timelineItem}>
                        <TimelineSeparator>
                            <TimelineDot className={classes.timelineDot}>
                                {item.status === OrderStatusEnum.DONE && <CompletedStatus />}
                                {item.status === OrderStatusEnum.IN_PROGRESS && <InProgressStatus />}
                                {item.status === OrderStatusEnum.NEW && <NotCompletedStatus />}
                                {item.status === OrderStatusEnum.WARN && <WarnStatus />}
                                {item.status === OrderStatusEnum.ERROR && <ErrorStatus />}
                            </TimelineDot>
                            {index !== statuses.length - 1 && (
                                <TimelineConnector
                                    className={classes.timelineConnector}
                                    style={{
                                        backgroundImage: getGradient(item.status, statuses[index + 1].status),
                                    }}
                                />
                            )}
                        </TimelineSeparator>
                        <TimelineContent style={{ flex: 'unset' }}>
                            <Box color={item.status === OrderStatusEnum.NEW ? colors.lightDarkerGray : colors.black}>
                                <Typography variant={'subtitle1'} color={'inherit'}>
                                    {item.title}
                                </Typography>
                                <Typography variant={'subtitle2'} color={'inherit'}>
                                    {item.details}
                                </Typography>
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                )
            })}
        </Timeline>
    )
}

export default OrderStatus
