const createStandardXHR = (): XMLHttpRequest | null => {
    try {
        return new window.XMLHttpRequest()
    } catch (e) {
        return null
    }
}

const createActiveXHR = (): XMLHttpRequest | null => {
    try {
        // @ts-expect-error ActiveXObject only on Internet Explorer
        return new window.ActiveXObject('Microsoft.XMLHTTP')
    } catch (e) {
        return null
    }
}

const createXHR = () => {
    // @ts-expect-error ActiveXObject only on Internet Explorer
    if (typeof window !== 'undefined' && window.ActiveXObject) {
        return createActiveXHR()
        // createStandardXHR() ||
    }
    return createStandardXHR()
}

const getBinaryContent = (path: string, callback: (error: any, data: any) => void): Promise<any> | undefined => {
    const resolve = (data: any) => callback(null, data)
    const reject = (error: any) => callback(error, null)

    try {
        const xhr = createXHR()
        if (xhr) {
            xhr.open('GET', path, true)
            if ('responseType' in xhr) {
                xhr.responseType = 'arraybuffer'
            }

            if (xhr.overrideMimeType) {
                xhr.overrideMimeType('text/plain; charset=x-user-defined')
            }

            xhr.onreadystatechange = function (this: XMLHttpRequest) {
                if (xhr) {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200 || xhr.status === 0) {
                            try {
                                resolve(JSZipUtils._getBinaryFromXHR(xhr))
                            } catch (e) {
                                reject(new Error(e))
                            }
                        } else {
                            reject(new Error('ajax error for ' + path + ' : ' + this.status + ' ' + this.statusText))
                        }
                    }
                }
            }

            xhr.send()
        }
    } catch (e) {
        reject(new Error(e))
    }

    return undefined
}

const JSZipUtils = {
    // just use the responseText with xhr1, response with xhr2.
    // The transformation doesn't throw away high-order byte (with responseText)
    // because JSZip handles that case. If not used with JSZip, you may need to
    // do it, see https://developer.mozilla.org/En/Using_XMLHttpRequest#Handling_binary_data
    _getBinaryFromXHR: (xhr: XMLHttpRequest): any | string => {
        // for xhr.responseText, the 0xFF mask is applied by JSZip
        if (xhr.responseType !== 'arraybuffer' && xhr.responseText) {
            return xhr.responseText
        }
        return xhr.response
    },
    getBinaryContent,
}

export default JSZipUtils
