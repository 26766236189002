import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography'

interface FontStyle {
    [key: string]: TypographyStyleOptions
}

const fontStyles: FontStyle = {
    Title: {
        fontFamily: 'Source Sans pro',
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: '3rem',
        lineHeight: 1.2,
    },
    Title2: {
        fontFamily: 'Source Sans pro',
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: '2rem',
        lineHeight: 1.2,
    },
    Title3: {
        fontFamily: 'Source Sans pro',
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: '1.5rem',
        lineHeight: 1.2,
    },
    Subtitle: {
        fontFamily: 'Source Sans pro',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1.25rem',
        lineHeight: 1.2,
    },
    NormalText: {
        fontFamily: 'Source Sans pro',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '1rem',
        lineHeight: 1.5,
    },
    SmallText: {
        fontFamily: 'Source Sans pro',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '0.75rem',
        lineHeight: 0.85,
    },
    ButtonText: {
        fontFamily: 'Source Sans pro',
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: '1rem',
        lineHeight: 1.2,
    },
}

export default fontStyles
