import { Customize } from 'utils/customize/customize'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ErrorBoxProps } from 'components/ErrorBox/ErrorBox'
import { CHECK_LINK } from 'graphql/queries/CheckLink'
import { VALIDATE_REGISTRATION } from 'graphql/queries/ValidateRegistration'
import { Mutation, MutationValidateRegistrationArgs, Query, QueryCheckLinkArgs } from 'graphql/types'
import { History } from 'history'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { passwordValidator } from 'utils/password-validator/passwordValidator'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'

type validateRegistrationState = {
    success: boolean
    loading: boolean
    errorBox: ErrorBoxProps
    history: History<unknown>
    submit: () => void
    id: string
    setId: Dispatch<SetStateAction<string>>
    key: string
    setKey: Dispatch<SetStateAction<string>>
    password: string
    setPassword: Dispatch<SetStateAction<string>>
    password2: string
    setPassword2: Dispatch<SetStateAction<string>>
    isValid: () => void
    showPass: boolean
    setShowPass: Dispatch<SetStateAction<boolean>>
    isLinkValid: boolean
    isLinkExpired: boolean
    customize: Customize | undefined
}

type ValidateParams = {
    id: string
    key: string
}

export const useValidateRegistration = (): validateRegistrationState => {
    const history = useHistory()
    const { t } = useTranslation()
    const [errorBox, setErrorBox] = useState<ErrorBoxProps>({ errors: [], errorsTitle: undefined })
    const [success, setSuccess] = useState(false)
    const params = useParams<ValidateParams>()
    const [id, setId] = useState(params.id ? params.id : '')
    const [key, setKey] = useState(params.key ? params.key : '')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [showPass, setShowPass] = useState(false)
    const [isLinkValid, setIsLinkValid] = useState(false)
    const [isLinkExpired, setIsLinkExpired] = useState(false)

    const { customize } = useSelector((appState: AppState) => ({
        customize: appState.generalState.customize,
    }))

    const [validateRegistration, { loading }] = useMutation<Mutation>(VALIDATE_REGISTRATION, {
        onCompleted: (data) => {
            if (data.validateRegistration === true) {
                setSuccess(true)
            } else {
                setErrorBox({ errors: [t('registerFail')], errorsTitle: '' })
            }
        },
    })

    const [fetchCheckLink] = useLazyQuery<Query>(CHECK_LINK, {
        onCompleted: (data) => {
            if (data.checkLink) {
                setIsLinkExpired(data.checkLink.isExipired)
                setIsLinkValid(data.checkLink.isValid)
            }
        },
    })

    const isValid = (): boolean => {
        let valid = true
        const e: ErrorBoxProps = { errors: [], errorsTitle: 'test' }
        if (password !== password2) {
            e.errors.push('pwNotMatch')
            valid = false
        }
        if (password === '') {
            e.errors.push('pw1Empty')
            valid = false
        }
        if (password2 === '') {
            e.errors.push('pw2Empty')
            valid = false
        }
        const result = passwordValidator(password, {
            minLength: 8,
            maxLength: 50,
            digits: true,
            letters: true,
            uppercase: true,
            lowercase: true,
            symbols: true,
        })
        if (!result.valid) {
            e.errors.push('isPasswordSecure')
            valid = false
        }
        setErrorBox(e)
        return valid
    }

    const completeRegistration = () => {
        if (isValid()) {
            const vars: MutationValidateRegistrationArgs = {
                customerID: id,
                registrationKey: key,
                pw: password,
            }
            validateRegistration({ variables: vars })
        }
    }

    useEffect(() => {
        if (key !== '' && id !== '') {
            const vars: QueryCheckLinkArgs = {
                input: {
                    customerID: id,
                    key,
                },
            }
            fetchCheckLink({ variables: vars })
        }
    }, [key, id])

    return {
        success,
        loading,
        errorBox,
        history,
        submit: completeRegistration,
        id,
        setId,
        key,
        setKey,
        password,
        setPassword,
        password2,
        setPassword2,
        isValid,
        showPass,
        setShowPass,
        isLinkValid,
        isLinkExpired,
        customize,
    }
}
