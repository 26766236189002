import { Grid, Typography } from '@material-ui/core'
import LoginData from 'components/LoginData'
import MyDataItem from 'components/MyDataItem/MyDataItem'
import NumberBlock from 'components/NumberBlock/NumberBlock'
import PersonalData from 'components/PersonalData'
import ViewWrapper from 'components/ViewWrapper'
import useMyDataState from 'pages/MyDataPage/useMyDataState'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import colors from 'theme/colors'
import { getFormattedIbanByInput, getHeader, navigationLinks } from 'utils/utils'

const MyDataPage = (): JSX.Element => {
    const { t } = useTranslation()
    const history = useHistory()
    const { user, searchTerms, handlePasswordChange, customize } = useMyDataState()

    if (Object.keys(user).length === 0) {
        history.push('/')
        return <></>
    }

    return (
        <ViewWrapper
            header={getHeader(Routes.Dashboard, history, searchTerms, customize)}
            navigation={navigationLinks('logged-in', 'myData')}
        >
            <Grid container spacing={4} direction="row" justify="space-between" alignItems="stretch">
                <Grid item xs={12} style={{ marginBottom: 40 }}>
                    <Typography variant={'h1'}>{t('pages.myData.title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <PersonalData user={user} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MyDataItem
                        title={t('pages.myData.OtherAddr.title')}
                        helper={t('pages.myData.OtherAddr.helper')}
                        linkTarget={Routes.OtherAddrEdit}
                        buttonText={t('pages.myData.viewAddr')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MyDataItem
                        title={t('pages.myData.billing.title')}
                        linkTarget={Routes.BankDataEdit}
                        buttonText={t('pages.myData.billing.viewDetails')}
                    >
                        <Grid item container>
                            <Grid item xs={12}>
                                <Typography style={{ color: colors.midDarkerGray }}>
                                    {t('pages.myInvoices.billingBankData.accountHolder')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{user.bankData.accountHolder}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{ color: colors.midDarkerGray }}>
                                    {t('pages.myInvoices.billingBankData.iban')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{getFormattedIbanByInput(user.bankData.iban)}</Typography>
                            </Grid>
                        </Grid>
                    </MyDataItem>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h2'}>{t('numberLock.title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <MyDataItem>
                        <NumberBlock numbers={user.contractMetaData.phoneData} />
                    </MyDataItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MyDataItem
                        title={t('telephoneBookEntry.title')}
                        helper={t('telephoneBookEntry.helper')}
                        linkTarget={Routes.PhoneBookEntryEdit}
                        buttonText={t('telephoneBookEntry.btn')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MyDataItem
                        title={t('displayNumber.title')}
                        helper={t('displayNumber.helper')}
                        linkTarget={Routes.PhoneNumberDisplayEdit}
                        buttonText={t('displayNumber.btn')}
                    />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 100 }}>
                    <LoginData
                        data={{
                            username: user.customerID,
                            password: '',
                        }}
                        handlePasswordchange={handlePasswordChange}
                    />
                </Grid>
            </Grid>
        </ViewWrapper>
    )
}

export default MyDataPage
