import { Box, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import DashboardSectionBox from 'components/DashboardSectionBox'
import OrderStatus from 'components/OrderStatus'
import ViewWrapper from 'components/ViewWrapper'
import useDashboardState from 'pages/DashboardPage/useDashboardState'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import TestIDs from 'utils/TestIDs'
import { getHeader, navigationLinks } from 'utils/utils'

const DashboardPage = (): JSX.Element => {
    const { t } = useTranslation()
    const history = useHistory()

    const { searchTerms, orderStatus, customize, user } = useDashboardState()

    return (
        <ViewWrapper
            header={getHeader(Routes.Dashboard, history, searchTerms, customize)}
            navigation={navigationLinks('logged-in', 'dashboard')}
        >
            <Box display={'flex'} flexDirection={'column'} marginBottom={10}>
                <Box width={1}>
                    <Typography variant={'h1'} {...TestIDs.GET_DASHBOARD_PAGE('HEADLINE', 'MAIN')}>
                        {t('pages.dashboard.title.overview')}
                    </Typography>
                </Box>
                <Box marginTop={1}>
                    <Typography variant={'subtitle1'} {...TestIDs.GET_DASHBOARD_PAGE('SUBLINE', 'MAIN')}>
                        {t('pages.dashboard.subTitle.overview')}
                    </Typography>
                </Box>

                {orderStatus.length > 0 && (
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="flex-start"
                        alignItems="stretch"
                        style={{ marginTop: 30 }}
                    >
                        <Grid item xs={12}>
                            <Typography variant="h1">{t('pages.dashboard.orderStatus.title')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1" style={{ marginBottom: 20, fontWeight: 700 }}>
                                {t('pages.dashboard.orderStatus.info1')}
                            </Typography>
                            <Typography variant="body1" style={{ marginBottom: 20 }}>
                                {t('pages.dashboard.orderStatus.info2')}
                            </Typography>
                            <Typography variant="body1" style={{ marginBottom: 20 }}>
                                {t('pages.dashboard.orderStatus.info3')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DashboardSectionBox
                                title={''}
                                isTitleClickable={false}
                                testCategory={'MY_DATA_DASHBOARD'}
                                height={'100%'}
                            >
                                <OrderStatus statuses={orderStatus} />
                            </DashboardSectionBox>
                        </Grid>
                    </Grid>
                )}
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    style={{ marginTop: 1, marginBottom: 10 }}
                >
                    {user && user.isCATVCustomer && (
                        <Grid item xs={12} md={6}>
                            <DashboardSectionBox
                                title={t('pages.dashboard.catv.title')}
                                isTitleClickable={true}
                                buttonProps={{
                                    label: t('pages.dashboard.catv.btn'),
                                    onClick: (): void => {
                                        window.open(user.catvData, '_blank')?.focus()
                                    },
                                }}
                                testCategory={'CATV_DASHBOARD'}
                                height={'100%'}
                            >
                                <Typography style={{ marginTop: 16 }}>{t('pages.dashboard.catv.helper')}</Typography>
                            </DashboardSectionBox>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <DashboardSectionBox
                            title={t('pages.dashboard.myData.title')}
                            isTitleClickable={true}
                            buttonProps={{
                                label: t('pages.dashboard.myData.title'),
                                onClick: (): void => history.push(Routes.MyData),
                            }}
                            testCategory={'MY_DATA_DASHBOARD'}
                            height={'100%'}
                        >
                            <Typography style={{ marginTop: 16 }}>{t('pages.dashboard.myData.helper')}</Typography>
                        </DashboardSectionBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DashboardSectionBox
                            title={t('pages.dashboard.lastInvoice.title')}
                            isTitleClickable={true}
                            buttonProps={{
                                label: t('pages.dashboard.lastInvoice.submitButton'),
                                onClick: (): void => history.push(Routes.Invoices),
                            }}
                            testCategory={'LAST_INVOICE'}
                            height={'100%'}
                        >
                            <Typography style={{ marginTop: 16 }}>{t('pages.dashboard.lastInvoice.helper')}</Typography>
                        </DashboardSectionBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DashboardSectionBox
                            title={t('pages.dashboard.myContracts.title')}
                            isTitleClickable={true}
                            buttonProps={{
                                label: t('pages.dashboard.myContracts.submitButton'),
                                onClick: (): void => history.push('/contractOverview'),
                            }}
                            buttonProps2={{
                                label: t('pages.dashboard.myContracts.cancelButton'),
                                onClick: (): void => history.push('/cancelContract'),
                            }}
                            testCategory={'CONTRACT_OVERVIEW'}
                        >
                            <Typography style={{ marginTop: 16 }}>{t('pages.dashboard.myContracts.helper')}</Typography>
                        </DashboardSectionBox>
                    </Grid>
                </Grid>
            </Box>
        </ViewWrapper>
    )
}

export default DashboardPage
