export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    Any: any
}

export type Address = {
    __typename?: 'Address'
    salutation: Scalars['String']
    companyName: Scalars['String']
    companyLegalForm: Scalars['String']
    title: Scalars['String']
    firstName: Scalars['String']
    lastName: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    houseNumberExtension: Scalars['String']
    zipCode: Scalars['String']
    city: Scalars['String']
    country: Scalars['String']
}

export type AddressInput = {
    salutation: Scalars['String']
    companyName: Scalars['String']
    companyLegalForm: Scalars['String']
    title: Scalars['String']
    firstName: Scalars['String']
    lastName: Scalars['String']
    street: Scalars['String']
    houseNumber: Scalars['String']
    houseNumberExtension: Scalars['String']
    zipCode: Scalars['String']
    city: Scalars['String']
    country: Scalars['String']
}

export type AddressUpdateResponse = {
    __typename?: 'AddressUpdateResponse'
    Success: Scalars['Boolean']
    AddressKind: Scalars['String']
}

export type BankData = {
    __typename?: 'BankData'
    currentID: Scalars['String']
    accountHolder: Scalars['String']
    isTransfer: Scalars['Boolean']
    iban: Scalars['String']
    bic: Scalars['String']
    creditorID: Scalars['String']
    Mandatsreferenz: Scalars['String']
}

export type BankDataInput = {
    accountHolder: Scalars['String']
    accountNumber: Scalars['String']
    bankCode: Scalars['String']
    iban: Scalars['String']
}

export type BillingData = {
    __typename?: 'BillingData'
    address: Address
    salutation: Scalars['String']
    title: Scalars['String']
    firstName: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    birthdate: Scalars['String']
    telephone: Scalars['String']
    mobile: Scalars['String']
}

export type BillingDataInput = {
    address: AddressInput
    salutation: Scalars['String']
    title?: Maybe<Scalars['String']>
    firstName: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    birthdate: Scalars['String']
    telephone: Scalars['String']
    mobile: Scalars['String']
}

export type ContactFormInput = {
    subjectOption: Scalars['String']
    message: Scalars['String']
    email: Scalars['String']
    username: Scalars['String']
    customerID: Scalars['String']
}

export type Contract = {
    __typename?: 'Contract'
    name: Scalars['String']
    status: Scalars['String']
    contractNumber: Scalars['String']
    contractStart: Scalars['String']
    paymentFrequency: Scalars['String']
    products?: Maybe<Array<Maybe<ContractProducts>>>
}

export type ContractData = {
    __typename?: 'ContractData'
    contractID: Scalars['String']
    contractStart: Scalars['String']
    contractEnd: Scalars['String']
    possibleContractEnd: Scalars['String']
    lastDateForCancellation: Scalars['String']
}

export type ContractMetaData = {
    __typename?: 'ContractMetaData'
    activeContractIDs: Array<Scalars['String']>
    activeContracts: Array<ContractData>
    evn?: Maybe<Array<Evn>>
    sendInvoice: Array<ContractSendInvoice>
    phoneData: Array<PhoneResource>
}

export type ContractOption = {
    __typename?: 'ContractOption'
    status: Scalars['String']
    name: Scalars['String']
    contractNumber: Scalars['String']
    contractStart: Scalars['String']
    paymentFrequency: Scalars['String']
}

export type ContractOverview = {
    __typename?: 'ContractOverview'
    contractID: Scalars['String']
    datasheetLink: Scalars['String']
    inet?: Maybe<Package>
    tv?: Maybe<Package>
    phone?: Maybe<Package>
}

export type ContractProducts = {
    __typename?: 'ContractProducts'
    status: Scalars['String']
    name: Scalars['String']
    contractNumber: Scalars['String']
    contractStart: Scalars['String']
    paymentFrequency: Scalars['String']
    options?: Maybe<Array<Maybe<ContractOption>>>
}

export type ContractSendInvoice = {
    __typename?: 'ContractSendInvoice'
    contractID: Scalars['String']
    sendInvoicePostal: Scalars['Boolean']
    sendInvoiceKupo: Scalars['Boolean']
}

export type ContractTermination = {
    __typename?: 'ContractTermination'
    terminationReasons: TerminationReason
    terminations: Array<Termination>
}

export type ContractTerminationInput = {
    contractID: Scalars['String']
    targetEnd: Scalars['String']
    kind: Scalars['String']
    reason: Scalars['String']
    sendDate: Scalars['Int']
    email: Scalars['String']
    usernameFull: Scalars['String']
    articleDescription: Scalars['String']
    txt: Array<Scalars['String']>
}

export type CreateTicketInput = {
    title: Scalars['String']
    message: Scalars['String']
    topic: Scalars['String']
}

export type CustomPropsInput = {
    specifier: Scalars['String']
    value: Scalars['Any']
}

export type Doc = {
    __typename?: 'Doc'
    bucket: Scalars['String']
    contractID: Scalars['String']
    customerID: Scalars['String']
    docType: Scalars['String']
    path: Scalars['String']
    s3Host: Scalars['String']
    invoiceTarget: Scalars['String']
    docID: Scalars['String']
    displayName: Scalars['String']
    downloadName: Scalars['String']
    targetPeriodId: Scalars['String']
}

export type ErrorConfig = {
    __typename?: 'ErrorConfig'
    title: Scalars['String']
    content: Scalars['String']
    messages?: Maybe<Array<Scalars['String']>>
    btnLabel: Scalars['String']
}

export type Evn = {
    __typename?: 'Evn'
    contractID: Scalars['String']
    value: Scalars['String']
}

export type FaqCategory = {
    __typename?: 'FAQCategory'
    id: Scalars['ID']
    category: Scalars['String']
    questions: Array<FaqEntry>
}

export type FaqEntry = {
    __typename?: 'FAQEntry'
    id: Scalars['ID']
    question: Scalars['String']
    answer: Scalars['String']
    showOnOverview: Scalars['Boolean']
}

export type InetSpeed = {
    __typename?: 'InetSpeed'
    DownMin: Scalars['String']
    DownStd: Scalars['String']
    DownMax: Scalars['String']
    UpMin: Scalars['String']
    UpStd: Scalars['String']
    UpMax: Scalars['String']
}

export type Invoice = {
    __typename?: 'Invoice'
    date: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
    billingPeriod: Scalars['String']
    downloadURL: Scalars['String']
    price: Scalars['Float']
}

export type InvoiceChangeEmail = {
    content: Scalars['String']
}

export type IsValidRequest = {
    customerID: Scalars['String']
    key: Scalars['String']
}

export type IsValidResponse = {
    __typename?: 'IsValidResponse'
    isValid: Scalars['Boolean']
    isExipired: Scalars['Boolean']
}

export type Loadstate = {
    __typename?: 'Loadstate'
    loading: Scalars['Boolean']
    errors: Array<Scalars['String']>
}

export type LoggedUser = {
    __typename?: 'LoggedUser'
    id: Scalars['ID']
    email: Scalars['String']
    username: Scalars['String']
    roles: Array<Scalars['String']>
}

export type Mutation = {
    __typename?: 'Mutation'
    createTicket: Scalars['Boolean']
    updateUser: Scalars['Boolean']
    updateCustomerPayment: Scalars['Boolean']
    updateCustomerAddress: AddressUpdateResponse
    updateLoginEmail: Scalars['Boolean']
    updateLoginPW: Scalars['Boolean']
    validateRegistration: Scalars['Boolean']
    reactivateRegistration: Scalars['Boolean']
    deleteCustomerAddress: Scalars['Boolean']
    patchCustomerProps: Scalars['Boolean']
    sendPostalChangeJob: Scalars['Boolean']
    sendContractTermination: Scalars['Boolean']
    sendContractTerminationMailAgain: Scalars['Boolean']
    updatePhoneResource: Scalars['Boolean']
    setPhoneBookEntry: Scalars['Boolean']
    sendContactForm: Scalars['Boolean']
    updatePhoneBlockData: Scalars['Boolean']
}

export type MutationCreateTicketArgs = {
    input: CreateTicketInput
}

export type MutationUpdateUserArgs = {
    input: UserInput
}

export type MutationUpdateCustomerPaymentArgs = {
    bankData: PaymentInfoInput
    paymentID: Scalars['String']
}

export type MutationUpdateCustomerAddressArgs = {
    input: AddressInput
    kind: Scalars['String']
}

export type MutationUpdateLoginEmailArgs = {
    email: Scalars['String']
}

export type MutationValidateRegistrationArgs = {
    customerID: Scalars['String']
    registrationKey: Scalars['String']
    pw: Scalars['String']
}

export type MutationReactivateRegistrationArgs = {
    customerID: Scalars['String']
}

export type MutationDeleteCustomerAddressArgs = {
    kind: Scalars['String']
}

export type MutationPatchCustomerPropsArgs = {
    input: Array<CustomPropsInput>
}

export type MutationSendPostalChangeJobArgs = {
    input: PostalChangeInput
}

export type MutationSendContractTerminationArgs = {
    input: ContractTerminationInput
}

export type MutationSendContractTerminationMailAgainArgs = {
    input: ContractTerminationInput
}

export type MutationUpdatePhoneResourceArgs = {
    input: PhoneResourceInput
}

export type MutationSetPhoneBookEntryArgs = {
    input: PhoneBookEntryInput
}

export type MutationSendContactFormArgs = {
    input: ContactFormInput
}

export type MutationUpdatePhoneBlockDataArgs = {
    input: PhoneBlockedDataInput
}

export type OrderStatus = {
    __typename?: 'OrderStatus'
    title: Scalars['String']
    status: OrderStatusEnum
    details: Scalars['String']
}

export enum OrderStatusEnum {
    ERROR = 'ERROR',
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
    WARN = 'WARN',
}

export type OrderStatusInput = {
    contractID: Scalars['ID']
}

export type Package = {
    __typename?: 'Package'
    periodStart: Scalars['String']
    periodEnd: Scalars['String']
    earliestCancellationDate: Scalars['String']
    serviceDescription: Scalars['String']
    baseTypeID: Scalars['Int']
    baseTypeName: Scalars['String']
    Products: Array<Product>
    Hardware: Array<Product>
}

export type PaymentInfoInput = {
    iban: Scalars['String']
    bic: Scalars['String']
    accountHolder: Scalars['String']
}

export type PhoneBlockedData = {
    __typename?: 'PhoneBlockedData'
    number: Scalars['String']
    isDisabled: Scalars['Boolean']
    isBlocked: Scalars['Boolean']
    description: Scalars['String']
    locks: Array<Scalars['String']>
}

export type PhoneBlockedDataInput = {
    phoneNumber: Scalars['String']
    passable: Array<Scalars['String']>
    blocked: Array<Scalars['String']>
}

export type PhoneBookEntry = {
    __typename?: 'PhoneBookEntry'
    phoneNumber: Scalars['String']
    electronic: Scalars['Boolean']
    infoService: Scalars['Boolean']
    inverse: Scalars['Boolean']
    printedParticipantDir: Scalars['Boolean']
    withAddr: Scalars['Boolean']
}

export type PhoneBookEntryInput = {
    phoneNumber: Scalars['String']
    electronic: Scalars['Boolean']
    infoService: Scalars['Boolean']
    inverse: Scalars['Boolean']
    printedParticipantDir: Scalars['Boolean']
    withAddr: Scalars['Boolean']
}

export type PhoneResource = {
    __typename?: 'PhoneResource'
    id: Scalars['Int']
    typeID: Scalars['Int']
    cos: Scalars['String']
    clir: Scalars['String']
    phoneNumber: Scalars['String']
}

export type PhoneResourceInput = {
    id: Scalars['Int']
    typeID: Scalars['Int']
    cos?: Maybe<Scalars['String']>
    clir?: Maybe<Scalars['String']>
}

export type PostalChangeInput = {
    email?: Maybe<Scalars['Boolean']>
    mail?: Maybe<Scalars['Boolean']>
    optInTextEmail: Scalars['String']
    optInTextMail: Scalars['String']
    contractID: Scalars['String']
}

export type Product = {
    __typename?: 'Product'
    name: Scalars['String']
    articleNumber: Scalars['String']
    monthlyPrice: Scalars['Float']
    Options?: Maybe<Array<Product>>
    phoneNumbers?: Maybe<Array<Scalars['String']>>
    inetSpeeds?: Maybe<InetSpeed>
}

export type Query = {
    __typename?: 'Query'
    getOrderStatus: Array<OrderStatus>
    getSystemsStatuses: Array<StatusIndicator>
    getFAQs: Array<FaqCategory>
    getUserData: User
    getContracts: Array<Contract>
    getCustomerAddresses: Array<Address>
    searchDocs: Array<Doc>
    getSumForBills: Array<SumBill>
    checkLink: IsValidResponse
    requestUsername?: Maybe<Scalars['Boolean']>
    getContractOverview: ContractOverview
    getContractsTermination: ContractTermination
    getContractMetaData: ContractMetaData
    getPhoneResources: Array<PhoneResource>
    getPhoneBookEntry: PhoneBookEntry
    getPhoneBlockedData: Array<PhoneBlockedData>
}

export type QueryGetOrderStatusArgs = {
    input: OrderStatusInput
}

export type QuerySearchDocsArgs = {
    docType: Array<Scalars['String']>
}

export type QueryGetSumForBillsArgs = {
    periodIDs: Array<Scalars['String']>
}

export type QueryCheckLinkArgs = {
    input: IsValidRequest
}

export type QueryRequestUsernameArgs = {
    email: Scalars['String']
}

export type QueryGetContractOverviewArgs = {
    contractID: Scalars['String']
}

export type QueryGetPhoneResourcesArgs = {
    contractID: Scalars['String']
}

export type QueryGetPhoneBookEntryArgs = {
    phoneNumber: Scalars['String']
}

export type QueryGetPhoneBlockedDataArgs = {
    phoneNumber: Scalars['String']
}

export type RegisterUser = {
    ID: Scalars['String']
    salutation: Scalars['String']
    title: Scalars['String']
    firstName: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    birthdate: Scalars['String']
    telephone: Scalars['String']
    mobile: Scalars['String']
    newsletterEmail: Scalars['String']
    offers: Scalars['Boolean']
    tips: Scalars['Boolean']
    contactAddress: AddressInput
    password: Scalars['String']
}

export type StatusIndicator = {
    __typename?: 'StatusIndicator'
    system: Scalars['String']
    status: Scalars['Boolean']
    errorMessage: Scalars['String']
}

export type SumBill = {
    __typename?: 'SumBill'
    docID: Scalars['String']
    sumTotal: SumTotal
}

export type SumTotal = {
    __typename?: 'SumTotal'
    netto: Scalars['Float']
    containedVat: Scalars['Float']
    brutto: Scalars['Float']
}

export type Termination = {
    __typename?: 'Termination'
    contractID: Scalars['String']
    articleDescription: Scalars['String']
    targetEnd: Scalars['String']
}

export type TerminationReason = {
    __typename?: 'TerminationReason'
    terminationForConvenience: Array<Scalars['String']>
    terminationForCause: Array<Scalars['String']>
}

export type TipsTricks = {
    __typename?: 'TipsTricks'
    id: Scalars['ID']
    title: Scalars['String']
    showOnOverview: Scalars['Boolean']
    content: Scalars['String']
}

export type User = {
    __typename?: 'User'
    customerID: Scalars['String']
    salutation: Scalars['String']
    title: Scalars['String']
    firstName: Scalars['String']
    lastName: Scalars['String']
    company: Scalars['String']
    companyLegalForm: Scalars['String']
    email: Scalars['String']
    birthdate: Scalars['String']
    telephone: Scalars['String']
    mobile: Scalars['String']
    newsletterEmail: Scalars['String']
    twoFA: Scalars['Boolean']
    offers: Scalars['Boolean']
    tips: Scalars['Boolean']
    connectionAddress: Address
    billingAddress: Address
    deliveryAddress: Address
    accountHolderAddress: Address
    bankData: BankData
    contractMetaData: ContractMetaData
    isCATVCustomer: Scalars['Boolean']
    catvData: Scalars['String']
}

export type UserInput = {
    id: Scalars['ID']
    customerNumber: Scalars['String']
    salutation: Scalars['String']
    title: Scalars['String']
    firstName: Scalars['String']
    lastName: Scalars['String']
    email: Scalars['String']
    birthdate: Scalars['String']
    telephone: Scalars['String']
    mobile: Scalars['String']
    newsletterEmail: Scalars['String']
    twoFA: Scalars['Boolean']
    password: Scalars['String']
    contactAddress: AddressInput
    connectionAddress: AddressInput
    oneTimePassword: Scalars['String']
    registered: Scalars['Boolean']
    username: Scalars['String']
    securityAnswer: Scalars['String']
    securityQuestion: Scalars['String']
    offers: Scalars['Boolean']
    tips: Scalars['Boolean']
    bankData: BankDataInput
    billingData: BillingDataInput
}
