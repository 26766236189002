import { Box, ButtonBase, Collapse, Typography } from '@material-ui/core'
import { FaqCategory, FaqEntry } from 'graphql/types'
import React, { useLayoutEffect, useState } from 'react'
import colors from 'theme/colors'
import TestIDs from 'utils/TestIDs'

export interface HelpCollapsableProps {
    faqs: FaqCategory
    selectedFaq?: string
}

const HelpCollapsable = (props: HelpCollapsableProps): JSX.Element => {
    const { faqs, selectedFaq } = props
    const [openedFaqs, setOpenedFaqs] = useState<string[]>(selectedFaq ? [selectedFaq] : [])

    useLayoutEffect(() => {
        setOpenedFaqs(selectedFaq ? [selectedFaq] : [])
    }, [props])

    const openFaq = (faqID: string): void => {
        const currentlyOpenedFaqs = [...openedFaqs]
        if (!currentlyOpenedFaqs.includes(faqID)) {
            currentlyOpenedFaqs.push(faqID)
            setOpenedFaqs(currentlyOpenedFaqs)
        }
    }
    const closeFaq = (faqID: string): void => {
        const currentlyOpenedFaqs = [...openedFaqs]
        const faqToCloseIndex = currentlyOpenedFaqs.findIndex((id) => id === faqID)
        if (faqToCloseIndex !== -1) {
            currentlyOpenedFaqs.splice(faqToCloseIndex, 1)
            setOpenedFaqs(currentlyOpenedFaqs)
        }
    }

    const renderRow = (question: FaqEntry, i: number): JSX.Element => (
        <Box
            key={question.id}
            id={question.id}
            borderTop={`${i === 0 ? 2 : 1}px solid ${colors.lightBorderGray}`}
            borderBottom={`${i === faqs.questions.length - 1 ? 2 : 1}px solid ${colors.lightBorderGray}`}
        >
            <ButtonBase
                {...TestIDs.GET_HELP_DETAILS('QUESTION', question.id)}
                onClick={() => {
                    if (openedFaqs.includes(question.id)) {
                        closeFaq(question.id)
                    } else {
                        openFaq(question.id)
                    }
                }}
                style={{ width: '100%' }}
                TouchRippleProps={{ style: { color: colors.lightDarkerGray } }}
            >
                <Box
                    paddingTop={{ xs: 2, sm: 4 }}
                    paddingBottom={{ xs: 2, sm: 4 }}
                    flex={1}
                    paddingLeft={{ xs: 2, sm: 3 }}
                    paddingRight={{ xs: 2, sm: 3 }}
                    display={'flex'}
                    justifyContent={'space-between'}
                >
                    <Typography variant={'h2'} className={'CollapsableTitle'}>
                        {question.question}
                    </Typography>
                    <img
                        alt={'/\\'}
                        src={process.env.PUBLIC_URL + '/customize/icons/dropdown.svg'}
                        style={{
                            transform: `rotate(${openedFaqs.includes(question.id) ? '180deg' : '0deg'})`,
                            transition: 'transform 250ms',
                            width: 27,
                        }}
                    />
                </Box>
            </ButtonBase>
            <Box>
                <Collapse in={openedFaqs.includes(question.id)} unmountOnExit>
                    <Box padding={3}>
                        <Typography
                            dangerouslySetInnerHTML={{ __html: question.answer }}
                            {...TestIDs.GET_HELP_DETAILS('ANSWER', question.id)}
                        />
                    </Box>
                </Collapse>
            </Box>
        </Box>
    )

    return <Box {...TestIDs.GET_HELP_DETAILS('FAQ_LIST', 'collapse')}>{faqs.questions.map(renderRow)}</Box>
}

export default HelpCollapsable
