import { SearchOption } from 'components/SearchBar/SearchBar'
import { FaqCategory, User } from 'graphql/types'
import { HelpDetailsProps } from 'pages/HelpDetails/HelpDetails'
import { Dispatch, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { AppState } from 'store/store'
import { Customize } from 'utils/customize/customize'
import { FAQS_TIPS } from 'utils/utils'
import { TipsTricks } from '../../graphql/types'

interface useHelpDetailsState {
    faqList: FaqCategory[]
    tipsList: TipsTricks[]
    currentFaqCategory: FaqCategory | undefined
    user: User | undefined
    searchTerms: SearchOption[]
    customize: Customize | undefined
}

const useHelpDetailsState = (props: HelpDetailsProps): useHelpDetailsState => {
    const { categoryID, questionID } = props
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()

    const { faqList, tipsList, user, searchTerms, customize } = useSelector((appState: AppState) => ({
        faqList: appState.generalState.faqList,
        tipsList: appState.generalState.tipsList,
        user: appState.generalState.user,
        searchTerms: appState.generalState.searchTerms,
        customize: appState.generalState.customize,
    }))
    const [currentFaqCategory, setCurrentFaqCategory] = useState<FaqCategory>()

    const setFaqList = useCallback(
        (payload: FaqCategory[]) => {
            dispatch({
                type: GeneralStateAction.SET_FAQ_LIST,
                payload,
            })
        },
        [dispatch],
    )

    const setTipsList = useCallback(
        (payload: TipsTricks[]) => {
            dispatch({
                type: GeneralStateAction.SET_TIPS_LIST,
                payload,
            })
        },
        [dispatch],
    )

    useLayoutEffect(() => {
        // @ts-expect-error: Importing faqs_tips from public folder
        const faqs_tips: FAQS_TIPS = window.faqs_tips
        if (faqList.length === 0) {
            setFaqList(faqs_tips.faqs)
            setTipsList(faqs_tips.tips_and_tricks)
        }
        const currentFaqCategory = faqs_tips.faqs.find((faq) => {
            return faq.id === categoryID
        })
        if (currentFaqCategory) {
            setCurrentFaqCategory(currentFaqCategory)
        }
    })

    useEffect(() => {
        if (questionID) {
            const questionToScrollTo = document.getElementById(questionID)
            if (questionToScrollTo) {
                questionToScrollTo.scrollIntoView()
            }
        } else {
            window.scrollTo({ top: 0 })
        }
    })

    return {
        faqList,
        tipsList,
        currentFaqCategory,
        user,
        searchTerms,
        customize,
    }
}

export default useHelpDetailsState
