import { Grid, Link } from '@material-ui/core'
import DashboardSectionBox from 'components/DashboardSectionBox'
import { FaqCategory, FaqEntry, TipsTricks } from 'graphql/types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import TestIDs from 'utils/TestIDs'

export interface FaqTipsListProps {
    displayMethod: string
    faqList: FaqCategory[] | undefined
    tipsList: TipsTricks[] | undefined
    categoryToHide: string // Either a faq category id, or "tips"
}

const FaqTipsList = (props: FaqTipsListProps): JSX.Element => {
    const { displayMethod, categoryToHide, faqList, tipsList } = props
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <>
            {faqList &&
                faqList.length > 0 &&
                faqList
                    .filter((faqCategory: FaqCategory) => faqCategory.id !== categoryToHide)
                    .map((faqCategory: FaqCategory, i: number) => (
                        <Grid key={i} item xs={12} sm={6}>
                            <DashboardSectionBox
                                title={faqCategory.category}
                                isTitleClickable={true}
                                buttonProps={{
                                    label: t('general.more'),
                                    onClick: () => history.push(`${Routes.Help}/${displayMethod}/${faqCategory.id}`),
                                }}
                                height={1}
                                testCategory={faqCategory.category}
                            >
                                <Grid container spacing={2}>
                                    {faqCategory.questions
                                        .filter((faq: FaqEntry) => {
                                            return faq.showOnOverview
                                        })
                                        .map((faq: FaqEntry) => (
                                            <Grid item xs={12} sm={12} key={faq.id}>
                                                <Link
                                                    href={`#${Routes.Help}/${displayMethod}/${faqCategory.id}/${faq.id}`}
                                                    underline="always"
                                                    color="textPrimary"
                                                    variant="body1"
                                                    {...TestIDs.GET_HELP_OVERVIEW('HELP_ENTRY', faq.id)}
                                                >
                                                    {faq.question}
                                                </Link>
                                            </Grid>
                                        ))}
                                </Grid>
                            </DashboardSectionBox>
                        </Grid>
                    ))}
            {tipsList !== undefined && tipsList.length > 0 && categoryToHide !== 'tips' && (
                <Grid item xs={12}>
                    <DashboardSectionBox
                        title={t(`pages.helpOverview.tip.title`)}
                        isTitleClickable={true}
                        buttonProps={{
                            label: t(`pages.helpOverview.tip.more`),
                            onClick: () => history.push(Routes.Tips),
                        }}
                        testCategory={'tips'}
                    >
                        <Grid container spacing={2}>
                            {tipsList
                                .filter((tip: TipsTricks) => tip.showOnOverview)
                                .map((tip: TipsTricks, i: number) => (
                                    <Grid item xs={12} sm={12} key={i}>
                                        <Link
                                            onClick={() => history.push(`/tips/${tip.id}`)}
                                            variant={'body1'}
                                            style={{ cursor: 'pointer' }}
                                            underline={'always'}
                                            color={'textPrimary'}
                                            {...TestIDs.GET_TIPS_OVERVIEW('TIP_ENTRY', tip.id)}
                                        >
                                            {tip.title}
                                        </Link>
                                    </Grid>
                                ))}
                        </Grid>
                    </DashboardSectionBox>
                </Grid>
            )}
        </>
    )
}

export default FaqTipsList
