import { Box, Button, Link, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core'
import * as React from 'react'
import colors from 'theme/colors'
import TestIDs from 'utils/TestIDs'

export interface DashboardSectionBoxProps {
    title: string
    children: JSX.Element
    buttonProps?: {
        label: string
        onClick: () => void
    }
    buttonProps2?: {
        label: string
        onClick: () => void
        disabled?: boolean
    }
    testCategory?: string
    height?: any
    isTitleClickable?: boolean
    buttonSubtitle?: string
    displayButtonSubtitle?: boolean
}

const useStyles = makeStyles(() => ({
    childrenWrapper: {
        '& .MuiFilledInput-root': {
            backgroundColor: colors.white,
            '&.Mui-disabled': {
                backgroundColor: colors.lightGray,
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: colors.white,
            },
        },
    },
}))

const DashboardSectionBox = (props: DashboardSectionBoxProps): JSX.Element => {
    const {
        title,
        children,
        buttonProps,
        buttonProps2,
        testCategory,
        height,
        isTitleClickable,
        buttonSubtitle,
        displayButtonSubtitle,
    } = props
    const classes = useStyles()

    const disabled = displayButtonSubtitle || buttonProps2?.disabled

    return (
        <>
            <Box
                {...TestIDs.GET_DASHBOARD_SECTION_BOX('BOX', testCategory)}
                bgcolor={colors.lightGray}
                width={1}
                flex={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                padding={{ xs: 2, sm: 4 }}
                height={height}
            >
                <Box width={1} paddingBottom={2} textAlign={{ xs: 'center', sm: 'start' }}>
                    {isTitleClickable ? (
                        <Link
                            component={'a'}
                            underline={'hover'}
                            color={'textPrimary'}
                            onClick={buttonProps && buttonProps.onClick}
                            {...TestIDs.GET_DASHBOARD_SECTION_BOX('TITLE', testCategory + '_isClickable')}
                        >
                            <Typography
                                style={{ cursor: 'pointer' }}
                                variant={'h2'}
                                {...TestIDs.GET_DASHBOARD_SECTION_BOX('TITLE', testCategory)}
                            >
                                {title}
                            </Typography>
                        </Link>
                    ) : (
                        <Typography variant={'h2'} {...TestIDs.GET_DASHBOARD_SECTION_BOX('TITLE', testCategory)}>
                            {title}
                        </Typography>
                    )}
                </Box>
                {children && (
                    <Box
                        className={classes.childrenWrapper}
                        width={1}
                        flex={1}
                        display={'flex'}
                        flexDirection={'column'}
                    >
                        {children}
                    </Box>
                )}
                {buttonProps && (
                    <Box
                        width={1}
                        display={'flex'}
                        paddingTop={{ xs: 2, sm: 4 }}
                        justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    >
                        <Button
                            variant={'outlined'}
                            onClick={buttonProps.onClick}
                            style={{ minWidth: 250, backgroundColor: colors.white }}
                            fullWidth={useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))}
                            {...TestIDs.GET_DASHBOARD_SECTION_BOX('BUTTON', testCategory)}
                        >
                            {buttonProps.label}
                        </Button>
                    </Box>
                )}
                {buttonProps2 && (
                    <Box
                        width={1}
                        display={'flex'}
                        paddingTop={{ xs: 2, sm: 4 }}
                        justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    >
                        <Button
                            variant={'contained'}
                            disabled={disabled}
                            onClick={buttonProps2.onClick}
                            style={{
                                minWidth: 250,
                                backgroundColor: disabled ? colors.midGray : colors.main,
                                color: disabled ? colors.midDarkerGray : colors.white,
                            }}
                            fullWidth={useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))}
                            {...TestIDs.GET_DASHBOARD_SECTION_BOX('BUTTON2', testCategory)}
                        >
                            {buttonProps2.label}
                        </Button>
                    </Box>
                )}
            </Box>
            {buttonSubtitle && displayButtonSubtitle && displayButtonSubtitle && (
                <Box
                    bgcolor={colors.lightGray}
                    width={1}
                    flex={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    flexDirection={'column'}
                    marginTop={-2}
                    paddingX={1}
                    paddingBottom={1}
                >
                    <Typography variant={'caption'}>{buttonSubtitle}</Typography>
                </Box>
            )}
        </>
    )
}

export default DashboardSectionBox
