import { Customize } from 'utils/customize/customize'
import { useMutation } from '@apollo/client'
import { useKeycloak } from '@react-keycloak/web'
import UpdateLoginEmail from 'graphql/mutations/UpdateLoginEmail'
import { UPDATE_LOGIN_PW } from 'graphql/mutations/UpdateLoginPW'
import { LoggedUser, Mutation, User } from 'graphql/types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from 'store/store'

export interface LoginEditPageState {
    changeEmail: (email: string) => void
    changePassword: () => void
    user: User | undefined
    loggedUser: LoggedUser
    customize: Customize | undefined
}

const useLoginEditPageState = (): LoginEditPageState => {
    const history = useHistory()
    const { keycloak } = useKeycloak()
    const { user, loggedUser, customize } = useSelector((appState: AppState) => ({
        loggedUser: appState.generalState.loggedUser,
        user: appState.generalState.user,
        customize: appState.generalState.customize,
    }))

    const [updateLoginEmail] = useMutation<Mutation>(UpdateLoginEmail)

    const changeEmail = (email: string) => {
        updateLoginEmail({ variables: { email: email } }).then((resp) => {
            const response: Mutation = resp.data as Mutation
            if (response.updateLoginEmail === true) {
                history.push('/')
                keycloak.logout()
            } else {
                // TODO: handle/display error
            }
        })
    }

    const [updateLoginPW] = useMutation<Mutation>(UPDATE_LOGIN_PW)
    const changePassword = () => {
        updateLoginPW().then((resp) => {
            const response: Mutation = resp.data as Mutation
            if (response.updateLoginPW === true) {
                setTimeout(() => {
                    history.goBack()
                }, 5000)
            } else {
                // TODO: handle/display error
            }
        })
    }

    return {
        changeEmail,
        changePassword,
        user,
        loggedUser,
        customize,
    }
}

export default useLoginEditPageState
