import gql from 'graphql-tag'

export default gql`
    query GetOrderStatus($input: OrderStatusInput!) {
        getOrderStatus(input: $input) {
            status
            title
            details
        }
    }
`
