import { useMutation } from '@apollo/client'
import PhoneNumberDisplayEdit from 'components/PhoneNumberDisplayEdit'
import ViewWrapper from 'components/ViewWrapper'
import { UPDATE_PHONE_RESOURCE } from 'graphql/mutations/UpdatePhoneResource'
import { Mutation, MutationUpdatePhoneResourceArgs, PhoneResource } from 'graphql/types'
import { useSnackbar } from 'notistack'
import React, { Dispatch, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import GeneralStateActions, { GeneralStateAction } from 'store/GeneralState/GeneralState.actions'
import { AppState } from 'store/store'
import { getHeader, navigationLinks } from 'utils/utils'

const PhoneNumberDisplayEditPage = (): JSX.Element => {
    const { t } = useTranslation()
    const dispatch = useDispatch<Dispatch<GeneralStateActions>>()
    const [touched, setTouched] = useState(false)
    const [failed, setFailed] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const { user, customize } = useSelector((appState: AppState) => ({
        user: appState.generalState.user,
        customize: appState.generalState.customize,
    }))
    const [pushResource, { loading }] = useMutation<Mutation>(UPDATE_PHONE_RESOURCE, {
        onError: () => {
            setFailed(true)
        },
    })
    const handleSave = async (p: PhoneResource[]) => {
        for (let i = 0; i < p.length; i++) {
            const vars: MutationUpdatePhoneResourceArgs = {
                input: { id: p[i].id, typeID: p[i].typeID, clir: p[i].clir },
            }
            await pushResource({ variables: vars })
        }
        if (user) {
            dispatch({
                type: GeneralStateAction.SET_USER,
                payload: { ...user, contractMetaData: { ...user.contractMetaData, phoneData: p } },
            })
        }
        if (failed) {
            enqueueSnackbar(t('notification.saveFailed'), {
                variant: 'error',
            })
        } else {
            enqueueSnackbar(t('notification.saveSuccess'), {
                variant: 'success',
            })
            setTouched(false)
            //history.goBack()
        }
    }
    const history = useHistory()
    if (user === undefined || Object.keys(user ?? {}).length === 0) {
        history.push('/')
    }
    return (
        <ViewWrapper
            header={getHeader(Routes.PhoneBookEntryEdit, history, [], customize)}
            navigation={navigationLinks('logged-in', 'myData')}
        >
            {user && (
                <PhoneNumberDisplayEdit
                    touched={touched}
                    setTouched={setTouched}
                    phoneNumbersDisplay={user.contractMetaData.phoneData}
                    save={handleSave}
                    loading={loading}
                />
            )}
        </ViewWrapper>
    )
}

export default PhoneNumberDisplayEditPage
