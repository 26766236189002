import { Box, Button, Grid, Typography } from '@material-ui/core'
import CheckboxComponent from 'components/CheckboxComponent'
import { PhoneResource } from 'graphql/types'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import colors from 'theme/colors'

export interface PhoneNumberDisplayEditProps {
    phoneNumbersDisplay: PhoneResource[]
    loading: boolean
    touched: boolean
    setTouched: Dispatch<SetStateAction<boolean>>
    save: (data: PhoneResource[]) => void
}
const PhoneNumberDisplayEdit = (props: PhoneNumberDisplayEditProps): JSX.Element => {
    const { touched, setTouched, phoneNumbersDisplay, loading, save } = props
    const [phoneDisplayState, setPhoneDisplayState] = useState(phoneNumbersDisplay)
    const { t } = useTranslation()
    const handleSaveItems = () => {
        save(phoneDisplayState)
    }
    const history = useHistory()
    return (
        <Grid container spacing={6} justify="center" alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h1">{t('displayNumberEdit.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    justify="flex-start"
                    direction="row"
                    style={{ padding: 24, backgroundColor: colors.lightGray }}
                >
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1">{t('displayNumberEdit.helper')}</Typography>
                    </Grid>
                    {phoneDisplayState.map((p, i) => (
                        <React.Fragment key={i}>
                            <Grid item xs={6}>
                                <Box style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                    <Typography variant="body1">{p.phoneNumber}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <CheckboxComponent
                                    checked={p.clir === '0'}
                                    label={''}
                                    onSelect={() => {
                                        if (!touched) {
                                            setTouched(true)
                                        }
                                        const copyState = [...phoneDisplayState]
                                        if (copyState[i].clir === '1') {
                                            copyState[i] = { ...copyState[i], clir: '0' }
                                        } else {
                                            copyState[i] = { ...copyState[i], clir: '1' }
                                        }
                                        setPhoneDisplayState(copyState)
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <Box
                        marginTop={5}
                        marginBottom={5}
                        display={'flex'}
                        flexDirection={{ xs: 'column', md: 'row' }}
                        justifyContent={'space-between'}
                    >
                        <Button variant={'outlined'} color={'default'} onClick={() => history.goBack()}>
                            {touched ? t('general.cancel') : t('general.back')}
                        </Button>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleSaveItems}
                            disabled={!touched || loading}
                        >
                            {t('displayNumberEdit.save')}
                            {loading ? '...' : ''}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PhoneNumberDisplayEdit
