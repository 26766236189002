import { useLazyQuery, useMutation } from '@apollo/client'
import { Typography } from '@material-ui/core'
import LoadingSpinner from 'components/LoadingSpinner'
import { toDropDown } from 'components/NumberBlock/NumberBlock'
import PhoneBookEntry, { PhonebookOptions } from 'components/PhoneBookEntry/PhoneBookEntry'
import ViewWrapper from 'components/ViewWrapper'
import { SET_PHONEBOOKENTRY } from 'graphql/mutations/SetPhonebookentry'
import { GET_PHONEBOOKENTRY } from 'graphql/queries/GetPhonebookEntry'
import { Mutation, MutationSetPhoneBookEntryArgs, Query, QueryGetPhoneBookEntryArgs } from 'graphql/types'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { AppState } from 'store/store'
import { getHeader, navigationLinks } from 'utils/utils'

const PhoneBookEntryPage = (): JSX.Element => {
    const history = useHistory()
    const { t } = useTranslation()
    const { numbers, customize } = useSelector((state: AppState) => {
        return {
            numbers: state.generalState.user?.contractMetaData.phoneData,
            customize: state.generalState.customize,
        }
    })
    const [selected, setSelected] = useState(0)
    const [dropDownOpts, setDropDownOpts] = useState(toDropDown(numbers ? numbers : []))
    const [numberOption, setNumberOption] = useState<PhonebookOptions | undefined>(undefined)
    const handleNumberOption = (o: Partial<PhonebookOptions>) => {
        if (numberOption) {
            setNumberOption({ ...numberOption, ...o })
        }
    }
    const handleChangeOption = (e: any) => {
        const val = e.target.value as string
        let index = -1
        for (let i = 0; i < dropDownOpts.length; i++) {
            if (val === dropDownOpts[i].value) {
                index = i
                break
            }
        }
        if (index !== -1) {
            loadData(dropDownOpts[index].value)
            setSelected(index)
        }
    }
    const [fetchEntry, { loading }] = useLazyQuery<Query>(GET_PHONEBOOKENTRY, {
        nextFetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data.getPhoneBookEntry) {
                setNumberOption(data.getPhoneBookEntry)
            }
        },
    })
    const loadData = (phoneNumber: string): void => {
        if (phoneNumber === '') {
            return
        }
        const vars: QueryGetPhoneBookEntryArgs = {
            phoneNumber: phoneNumber.replaceAll('+49', ''),
        }
        fetchEntry({ variables: vars })
    }

    const { enqueueSnackbar } = useSnackbar()
    const [patchPhonebookEntry, { loading: loading2 }] = useMutation<Mutation>(SET_PHONEBOOKENTRY, {
        onCompleted: (data) => {
            if (data.setPhoneBookEntry) {
                enqueueSnackbar(t('notification.saveSuccess'), {
                    variant: 'success',
                })
            }
        },
    })
    const handleSave = (): void => {
        if (numberOption) {
            const vars: MutationSetPhoneBookEntryArgs = {
                input: {
                    phoneNumber: numberOption.phoneNumber.replaceAll('+49', ''),
                    electronic: numberOption.electronic,
                    infoService: numberOption.infoService,
                    inverse: numberOption.inverse,
                    printedParticipantDir: numberOption.printedParticipantDir,
                    withAddr: numberOption.withAddr,
                },
            }
            patchPhonebookEntry({ variables: vars })
        }
    }

    useEffect(() => {
        if (numbers) {
            setDropDownOpts(toDropDown(numbers))
        }
        if (numbers && numbers?.length > 0 && numberOption === undefined) {
            loadData(numbers[0].phoneNumber)
        }
    }, [numbers])

    return (
        <ViewWrapper
            header={getHeader(Routes.PhoneBookEntryEdit, history, [], customize)}
            navigation={navigationLinks('logged-in', 'myData')}
        >
            <>
                {loading && <LoadingSpinner loading={loading} />}
                {numbers && numberOption && !loading && (
                    <PhoneBookEntry
                        selected={selected}
                        handleChangeOption={handleChangeOption}
                        numbers={numbers}
                        dropDownOpts={dropDownOpts}
                        numberOption={numberOption}
                        handleNumberOption={handleNumberOption}
                        handleSave={handleSave}
                        saveLoading={loading2}
                    />
                )}
                {!numbers && <Typography>{t('phoneBookEntry.noNumbers')}</Typography>}
            </>
        </ViewWrapper>
    )
}

export default PhoneBookEntryPage
